import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FieldKeys } from "./useContactFields";

/**
 * Today/now
 */
const today = new Date();

/**
 * Initial Payload
 */
const initialPayload = {
	LeadID: undefined,
	Created: today.toISOString().split("T")[0],
	FirstName: "",
	MiddleName: "",
	LastName: "",
	Email: "",
	FICO: "",
	Phone: "",
	PropertyAddress: "",
	PropertyCity: "",
	PropertyState: "",
	PropertyZip: "",
	LoanOfficer: "",
	LoanPurpose: "",
	Address: "",
	City: "",
	State: "",
	Zip: "",
};

/**
 * Field Empty -- func
 * @param field
 */
const fieldEmpty = (field: string) => {
	return !field.trim() || field == null;
};

/**
 * Use Loan Application
 */
export default function useLoanApplication() {
	const [payload, setPayload] = useState(initialPayload);
	const agent = useSelector((state: any) => state.agent);
	const customer = useSelector((state: any) => state.customer);
	const [validated, setValidated] = useState(false);

	const submitLoanApplication = useCallback(
		async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();

			if (e.currentTarget.checkValidity() === false) {
			} else {
				let validationFailed = false;

				Object.entries(payload).forEach(([key, value]) => {
					const trimmedValue = value?.toString() ?? "";

					if (["LoanOfficer", "LoanPurpose", "FirstName", "LastName", "Email"].includes(key) && fieldEmpty(trimmedValue)) {
						toast.error(`Please fill out the ${key} field`);
						validationFailed = true;
					}
				});

				if (
					!confirm("Are you sure you would like to send this loan application? Please ensure that all fields are accurate.") ||
					validationFailed
				) {
					return;
				}

				try {
					const result = await axios.post("https://hooks.zapier.com/hooks/catch/11427652/3vs6j6z", JSON.stringify(payload));
					toast.success("Successfully submitted loan application");
				} catch (e) {
					toast.error("Failed to submit loan application");
				}
			}

			setValidated(true);
		},
		[payload]
	);

	//Init...
	useEffect(() => {
		if (agent) {
			setPayload((prev) => ({
				...prev,
				LoanOfficer: agent.email ?? "",
			}));
		}
	}, [agent]);

	useEffect(() => {
		if (customer?.rawContactObj?.fields) {
			setPayload((prev) => ({
				...prev,
				FirstName: customer.rawContactObj.fields[FieldKeys.FIRST_NAME] ?? "",
				LastName: customer.rawContactObj.fields[FieldKeys.LAST_NAME] ?? "",
				Email: customer.rawContactObj.fields[FieldKeys.EMAIL] ?? "",
				Phone: customer.calledFromNumber ?? "",
				PropertyAddress: customer.rawContactObj.fields[FieldKeys.STREET] ?? "",
				PropertyCity: customer.rawContactObj.fields[FieldKeys.CITY] ?? "",
				PropertyState: customer.rawContactObj.fields[FieldKeys.STATE] ?? "",
				PropertyZip: customer.rawContactObj.fields[FieldKeys.ZIP] ?? "",
				Address: customer.rawContactObj.fields[FieldKeys.STREET] ?? "",
				City: customer.rawContactObj.fields[FieldKeys.CITY] ?? "",
				State: customer.rawContactObj.fields[FieldKeys.STATE] ?? "",
				Zip: customer.rawContactObj.fields[FieldKeys.ZIP] ?? "",
				FICO: customer.rawContactObj.fields[FieldKeys.CREDIT_SCORE] ?? "",
				LoanPurpose: customer.rawContactObj.fields[FieldKeys.LOAN_PURPOSE] ?? "",
				LeadID: customer.rawContactObj.fields[FieldKeys.HUBSPOT_ID],
			}));
		}
	}, [customer]);

	useEffect(() => {
		if (!agent && !customer) {
			setPayload(initialPayload);
		}
	}, [agent, customer]);

	//Return
	return { validated, submitLoanApplication };
}
