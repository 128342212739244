import moment from "moment";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FieldKeys } from "../hooks/useContactFields";
import { getName, postMessage } from "../../utils";

/**
 * InterestRateModal
 * @constructor
 */
export default function InterestRateModal() {
	const [showModal, setShowModal] = useState(false);
	const [interestRate, setInterestRate] = useState("");
    const [interestRateError, setInterestRateError] = useState(false);
	const [loanDate, setLoanDate] = useState("");
	const customer = useSelector((state: any) => state.customer);
    const agent = useSelector((state: any) => state.agent);

	/**
	 * Change Interest -- fucn
	 * @param value
	 */
	const changeInterest = (value: string) => {
		const regex = /^\d*\.?\d{0,5}$/;
		if (regex.test(value)) {
			setInterestRate(value);
		}
	};

	/**
	 * Hide Modal -- func
	 */
	const hideModal = () => {
        setInterestRateError(false);
        setInterestRate("");
        setLoanDate("");
		setShowModal(false);
	};

	/**
	 * Save -- func
	 */
	const save = () => {
        if (!interestRate?.trim()) {
            setInterestRateError(true);
            toast.error("Interest Rate is required.");
            return;
        }

        const value: any = {
            user_name: agent.userName,
            InterestRate: interestRate,
            HubSpotID: customer?.rawContactObj?.fields?.[FieldKeys.HUBSPOT_ID]
        };

        if (loanDate) {
            value.LoanDate = moment(loanDate).format("MM/DD/YYYY");
        }

        postMessage({ action: "SaveInterestRate", value });
		hideModal();
	};

	//Return
	return (
		<>
			<Button
				onClick={() => {
					setShowModal(true);
				}}
				variant="info">
				Interest Rate
			</Button>
			<Modal centered onHide={() => hideModal()} show={showModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						Interest Rate -{" "}
						{getName({
							properties: {
								firstname: customer?.rawContactObj?.fields?.[FieldKeys.FIRST_NAME],
								lastname: customer?.rawContactObj?.fields?.[FieldKeys.LAST_NAME],
							},
						})}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Interest Rate <span style={{ color: "red"}}>*</span></Form.Label>
							<Form.Control
                                isInvalid={interestRateError}
								onChange={(e) => changeInterest(e.target.value)}
								placeholder="XX.XXXXX"
								type="text"
								value={interestRate}
							/>
							<Form.Text muted>Numeric format. Max 5 decimal places.</Form.Text>
							{interestRateError && <Form.Control.Feedback type="invalid">Interest Rate Required</Form.Control.Feedback>}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Loan Date</Form.Label>
							<Form.Control onChange={(e) => setLoanDate(e.target.value)} type="date" value={loanDate} />
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={hideModal} variant="secondary">
						Close
					</Button>
					<Button onClick={save} variant="primary">
						Submit
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
