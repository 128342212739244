import React, { useCallback, useEffect, useState, useRef } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { eventEmitter, notify, postMessage, readyStatePayload } from "../../utils";
import Dispositions from "../Dispositions";

/**
 * Alert Audio
 */
const alertAudio = new Audio("Alert.mp3");
alertAudio.loop = true;

/**
 * QueueWarningModal
 * @constructor
 */
const QueueWarningModal = () => {
	const [showModal, setShowModal] = useState(false);
	const [modalTimeout, setModalTimeout] = useState<NodeJS.Timeout | null>(null);
	const callStatus = useSelector((state: any) => state.callStatus);
	const notified = useRef(false);

	/**
	 * Handle Close -- func
	 */
	const handleClose = () => {
		setShowModal(false);
		notified.current = false;
		alertAudio.currentTime = 0;
		alertAudio.pause();
	};

	/**
	 * Clear Modal Timeout -- func
	 */
	const clearModalTimeout = useCallback(() => {
		if (modalTimeout) {
			clearTimeout(modalTimeout);
			notified.current = false;
			setModalTimeout(null);
		}
	}, [modalTimeout]);

	/**
	 * Show Modal Handler -- func
	 */
	const showModalHandler = useCallback(
		(show: boolean) => {
			if (show) {
				if (modalTimeout) {
					return;
				}

				if (!notified.current) {
					notify("There is an inbound transfer in the queue!");
					notified.current = true;
				}

				alertAudio.currentTime = 0;
				alertAudio.play();
			} else {
				alertAudio.currentTime = 0;
				alertAudio.pause();
				clearModalTimeout();
			}

			setShowModal(show);
		},
		[clearModalTimeout, modalTimeout, notified]
	);

	//Init...
	useEffect(() => {
		eventEmitter.on("showQueueWarning", showModalHandler);

		return () => {
			eventEmitter.off("showQueueWarning", showModalHandler);
		};
	}, [showModalHandler]);

	//Return
	return (
		<>
			<Modal
				centered
				onHide={() => {
					clearModalTimeout();
					setModalTimeout(setTimeout(() => showModalHandler(true), 15000));
					handleClose();
				}}
				show={showModal}>
				<Modal.Header>
					<Modal.Title>Transfer Waiting</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>There is an inbound transfer in the queue.</p>
					{callStatus === "WRAPUP" && (
						<strong>
							If performing after-call work. You MUST disposition the current call before a new call can be accepted.
						</strong>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Dispositions buttonLabel="Disposition Call..." onClick={() => handleClose()} />
					<Dropdown>
						<Dropdown.Toggle variant="secondary">Snooze...</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => {
									clearModalTimeout();
									setModalTimeout(setTimeout(() => showModalHandler(true), 60000));
									handleClose();
								}}>
								1 Minute
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<Button
						onClick={() => {
							clearModalTimeout();
							setModalTimeout(setTimeout(() => showModalHandler(true), 15000));
							handleClose();
						}}
						variant="secondary">
						Snooze 15 seconds
					</Button>
					{callStatus !== "WRAPUP" && (
						<Button
							onClick={() => {
								postMessage({ action: "ChangeReadyState", value: readyStatePayload() });
								handleClose();
							}}
							variant="success">
							Take Transfer
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default QueueWarningModal;
