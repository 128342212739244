import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
	setCustomer,
	setLisa,
	setNotes,
	setProductiveMins,
	setCallStartedAdtData,
	setAgentType,
	setCall,
	setAgent,
	setAgentOptions,
	setIsTransferred,
	setCcsStats,
	setLoStats,
	setCallStatus,
	setClientWeather,
	setReadyState,
	setLcCustomer,
	setHotlist,
	setReferralListItems,
	setAgentContact,
	setIsLOConnected,
	setIsNoteEditorReady,
	setCustomerActionScriptInput, setTransferAttempts,
	setIsRetryingCallCustomer
} from "../../redux/globalSlice";
import CallSurvey from "../CallSurvey";
import config from "../../config.json";
import {
	eventEmitter,
	postMessage,
	sortHotlistByDateFurthestOut
} from "../../utils";
import { Button } from "react-bootstrap";

/**
 * Use Message Listener
 */
export default function useMessageListener() {
	const dispatch = useDispatch();
	const dids = useRef<any[]>([]);

	useEffect(() => {
		/**
		 * HUB WINDOW POST MESSAGE - EVENT LISTENER
		 */
		document.addEventListener("hubWindowPostMessage", (e: any) => {
			if (e.detail.SSN4 !== undefined) {
				eventEmitter.emit("ssn4", e.detail.SSN4);
			}

			if (e.detail.AgentContact !== undefined) {
				dispatch(setAgentContact(e.detail.AgentContact));
			}

			if (e.detail.ClearDids !== undefined) {
				dids.current.forEach((did) => {
					toast.done(did);
				});
			}

			if (e.detail.DirectInboundCall) {
				const did = toast.info(
					<div>
						{e.detail.DirectInboundCall}
						<Button onClick={() => {
							postMessage({ action: "MuteAudio" });
							toast.dismiss();
						}} size="sm" style={{ marginTop: 10, width: "100%" }}>Ignore</Button>
					</div>,
					{
						autoClose: false,
						hideProgressBar: true,
						closeButton: false,
					}
				);

				dids.current.push(did);
			}

			if (e.detail.FollowUpProcessCallFinished) {
				eventEmitter.emit("followUpProcessCallFinished", { FailedDial: e.detail.FailedDial });
			}

			if (e.detail.CollapseAdt !== undefined) {
				eventEmitter.emit("collapseAdt", e.detail.CollapseAdt);
			}

			if (e.detail.SetCallbackTime !== undefined) {
				eventEmitter.emit("setCallbackTime", e.detail.SetCallbackTime);
			}

			if (e.detail.HotlistRemoved !== undefined) {
				eventEmitter.emit("hotlistRemoved", { HotlistRemoved: e.detail.HotlistRemoved, Id: e.detail.Id });
			}

			if (e.detail.Hotlist !== undefined) {
				dispatch(setHotlist(sortHotlistByDateFurthestOut(e.detail.Hotlist)));
			}

			//ReferralListItems
			if (e.detail.ReferralListItems !== undefined) {
				dispatch(setReferralListItems(e.detail.ReferralListItems));
			}

			//IsLOConnected
			if (e.detail.IsLOConnected !== undefined) {
				console.log(e.detail.IsLOConnected,"[INFO] EVENT - IsLOConnected - changing");
				dispatch(setIsLOConnected(e.detail.IsLOConnected));
			}

			//IsNoteEditorReady
			if (e.detail.IsNoteEditorReady !== undefined) {
				console.log(e.detail.IsNoteEditorReady,"[INFO] EVENT - IsNoteEditorReady - changing");
				dispatch(setIsNoteEditorReady(e.detail.IsNoteEditorReady));
			}

			//customerActionScriptInput
			if (e.detail.customerActionScriptInput !== undefined) {
				console.log(e.detail.customerActionScriptInput,"[INFO] EVENT - customerActionScriptInput - changing");
				dispatch(setCustomerActionScriptInput(e.detail.customerActionScriptInput));
			}

			if (e.detail.TransferProcessComplete !== undefined) {
				eventEmitter.emit("transferProcessComplete", e.detail.TransferProcessComplete);
			}

			if (e.detail.ShowLoUnavailable !== undefined) {
				eventEmitter.emit("showLoUnavailable", e.detail.ShowLoUnavailable);
			}

			if (e.detail.ShowQueueWarning !== undefined) {
				eventEmitter.emit("showQueueWarning", e.detail.ShowQueueWarning);
			}

			if (e.detail.Customer !== undefined) {
				dispatch(setCustomer(e.detail.Customer));
			}

			if (e.detail.LcCustomer !== undefined) {
				if (e.detail.LcCustomer === null) {
					dispatch(setLcCustomer(e.detail.LcCustomer));
				} else {
					let fields: any = {};

					Object.keys(e.detail.LcCustomer).forEach((key: any) => {
						fields = { ...fields, [(config.lcMapping as any)[key]]: e.detail.LcCustomer[key] };
					});

					let customer = { ...e.detail.LcCustomer };
					customer = { ...customer, rawContactObj: { fields } };
					dispatch(setCustomer(customer));
					dispatch(setLcCustomer(e.detail.LcCustomer));
				}
			}

			// if (e.detail.CallLogs !== undefined) {
			// 	dispatch(setCallLogs(e.detail.CallLogs));
			// }

			if (e.detail.CallFinishedCustomer !== undefined) {
				eventEmitter.emit("callFinished", e.detail.CallFinishedCustomer);
			}

			if (
				e.detail.CallFinishedAdtData !== undefined &&
				e.detail.CallFinishedCustomer !== undefined &&
				e.detail.CallFinishedAdtData.number?.startsWith("agent:")
			) {
				toast.info(
					<CallSurvey
						callFinishedAdtData={e.detail.CallFinishedAdtData}
						callFinishedCustomer={e.detail.CallFinishedCustomer}
					/>,
					{ closeOnClick: false, autoClose: 10000 }
				);
			}

			if (e.detail.SubmissionSentMessage !== undefined) {
				toast.info(e.detail.SubmissionSentMessage);
			}

			if (e.detail.ToastMessage !== undefined) {
				toast(e.detail.ToastMessage.Message, { type: e.detail.ToastMessage.Type });
			}

			if (e.detail.Lisa !== undefined) {
				dispatch(setLisa(e.detail.Lisa));
			}

			if (e.detail.Notes !== undefined) {
				if (e.detail.Id) {
					eventEmitter.emit("note", { note: e.detail.Notes, id: e.detail.Id });
				} else {
					dispatch(setNotes(e.detail.Notes));
				}
			}

			if (e.detail.ProductivityMinutes !== undefined) {
				dispatch(setProductiveMins(e.detail.ProductivityMinutes));
			}

			if (e.detail.CallStartedAdtData !== undefined) {
				eventEmitter.emit("callStarted", e.detail.CallStartedAdtData);
				dispatch(setCallStartedAdtData(e.detail.CallStartedAdtData));
			}

			if (e.detail.AgentType !== undefined) {
				dispatch(setAgentType(e.detail.AgentType));
			}

			if (e.detail.Call !== undefined) {
				dispatch(setCall(e.detail.Call));
			}

			if (e.detail.Agent !== undefined) {
				dispatch(setAgent(e.detail.Agent));
			}

			/**
			 * Map AgentOptions
			 */
			if (e.detail.AgentOptions !== undefined) {
				console.log(e.detail.AgentOptions, "[INFO] EVENT - AgentOptions - changing");
				dispatch(setAgentOptions(e.detail.AgentOptions));
			}

			if (e.detail.IsTransferred !== undefined) {
				dispatch(setIsTransferred(e.detail.IsTransferred));
			}

			if (e.detail.TransferAttempts !== undefined) {
				dispatch(setTransferAttempts(e.detail.TransferAttempts));
			}

			if (e.detail.CcsStats !== undefined) {
				dispatch(setCcsStats(e.detail.CcsStats));
			}

			if (e.detail.LoStats !== undefined) {
				dispatch(setLoStats(e.detail.LoStats));
			}

			if (e.detail.CallStatus !== undefined) {
				dispatch(setCallStatus(e.detail.CallStatus));
			}

			if (e.detail.ClientWeather !== undefined) {
				dispatch(setClientWeather(e.detail.ClientWeather));
			}

			if (e.detail.ReadyState !== undefined) {
				dispatch(setReadyState(e.detail.ReadyState));
			}

			if (e.detail.FailedManualCall !== undefined) {
				dispatch(setIsRetryingCallCustomer(false));
			}
		});
	}, [dispatch]);
}
