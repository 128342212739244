import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {eventEmitter, postMessage} from "../../utils";
import {useSelector} from "react-redux";
import {setAgentOptions} from "../../redux/globalSlice";
import {styled} from "styled-components";

/**
 * Five9 Feature Settings
 * =================================================
 * TAKEN FROM PAGE 299 - five9 doc
 *
 * OPTIONS:
 * ALERT_ON_INCOMING_MESSAGE
 *    Agent is notified of incoming instant messages and chat invitations. The feature is enabled by default. Must be processed by the client.
 * ALERT_ON_INCOMING_TEXT_INTERACTION
 *    Agent is notified of incoming chat, social, or email interactions. Must be processed by the client.
 *
 * ALERT_ON_OVERDUE_CALLBACK
 *    Agent is notified of overdue callbacks. The feature is enabled by default. Must be processed by the client.
 * AUTO_ACCEPT_SKILL_VOICEMAIL
 *    Skill voicemail messages are automatically accepted. Processed by the API.
 * AUTO_ANSWER_INBOUND_AUTODIAL_CALLS
 *    Autodial calls are automatically answered. The feature is enabled by default. Processed by the API.
 * AUTO_ANSWER_INTERNAL_CALLS
 *    Internal calls are automatically answered. The feature is enabled by default. Processed by the API.
 * AUTO_ANSWER_OUTBOUND_CALLS
 *    Outbound calls are automatically answered. The feature is enabled by default. Processed by the API.
 * ENABLE_CALLBACK_REMINDERS
 *    Agent is reminded when callbacks are overdue. The feature is enabled by default. Must be processed by the client.
 * ESTIMATED_END_OF_CALL_COUNTER
 *    In the Agent Desktop Plus application, a feature (Enable Extend Calls) enables the agent to activate in the lower right corner of the station a countdown timer to check the
 *    estimated end of call. The feature is enabled by default. Must be processed by the client.
 * PBX_MODE
 *    Agents are automatically ready to receive calls after logging in, and a disposition is set automatically when they hang up. Processed by the API.
 * SHOW_SEARCH_CONTENT
 *    Flag to show the search box.
 * SHOW_SELECT_SKILLS
 *    Agent selects skills from a list when logging in. The feature is enabled by default. Processed by the API.
 * TONE_ON_INCOMING_AUTODIAL_CALL
 *    Agent hears a tone for incoming autodial calls. Processed by the API.
 * TONE_ON_INCOMING_INBOUND_CALL
 *    Agent hears a tone for incoming inbound calls. The feature is enabled by default. Processed by the API.
 * TONE_ON_INCOMING_OUTBOUND_CALL
 *    Agent hears a tone for incoming outbound
 *  TONE_ON_INCOMING_PREVIEW_CALL
 *    Agent hears a tone for incoming preview calls. The feature is enabled by default. Processed by the API.
 */
const features = [
	/*{
		name: "ALERT_ON_OVERDUE_CALLBACK",
		description: "Agent is notified of overdue callbacks. The feature is enabled by default. Must be processed by the client."
	},*/
	/*{
		name: "AUTO_ACCEPT_SKILL_VOICEMAIL",
		description: "Skill voicemail messages are automatically accepted. Processed by the API."
	},*/
	/*{
		name: "AUTO_ANSWER_INBOUND_AUTODIAL_CALLS",
		description: "Autodial calls are automatically answered. The feature is enabled by default. Processed by the API."
	},*/
	/*{
		name: "AUTO_ANSWER_INTERNAL_CALLS",
		description: "Internal calls are automatically answered. The feature is enabled by default. Processed by the API."
	},*/
	/*{
		name: "AUTO_ANSWER_OUTBOUND_CALLS",
		description: "Outbound calls are automatically answered. The feature is enabled by default. Processed by the API."
	},*/
	/*{
		name: "ENABLE_CALLBACK_REMINDERS",
		description: "Agent is reminded when callbacks are overdue. The feature is enabled by default. Must be processed by the client."
	},*/
	/*{
		name: "ESTIMATED_END_OF_CALL_COUNTER",
		description: "In the Agent Desktop Plus application, a feature (Enable Extend Calls) enables the agent to activate in the lower right corner of the station a countdown timer to check the estimated end of call. The feature is enabled by default. Must be processed by the client."
	},*/

	/*
	TODO T: THIS COULD BE PONT. USED FOR PROCESSOR ROLE USERS
	{
		name: "PBX_MODE",
		description: "Agents are automatically ready to receive calls after logging in, and a disposition is set automatically when they hang up. Processed by the API."
	},*/
	/*{
		name: "SHOW_SEARCH_CONTENT",
		description: "Flag to show the search box."
	},*/
	/*{
		name: "SHOW_SELECT_SKILLS",
		description: "Agent selects skills from a list when logging in. The feature is enabled by default. Processed by the API."
	},*/

	{
		name: "TONE_ON_INCOMING_AUTODIAL_CALL",
		description: "Agent hears a tone for incoming autodial calls. Processed by the API."
	},
	{
		name: "TONE_ON_INCOMING_INBOUND_CALL",
		description: "Agent hears a tone for incoming inbound calls. The feature is enabled by default. Processed by the API."
	},
	{
		name: "TONE_ON_INCOMING_OUTBOUND_CALL",
		description: "Agent hears a tone for incoming outbound"
	},
	{
		name: "TONE_ON_INCOMING_PREVIEW_CALL",
		description: "Agent hears a tone for incoming preview calls. The feature is enabled by default. Processed by the API."
	}/*,	{
		name: "ALERT_ON_INCOMING_MESSAGE",
		description: "Agent is notified of incoming instant messages and chat invitations. The feature is enabled by default. Must be processed by the client."
	},	{
		name: "ALERT_ON_INCOMING_TEXT_INTERACTION",
		description: "Agent is notified of incoming chat, social, or email interactions. Must be processed by the client."
	}*/, {
		name: "TONE_ON_INCOMING_EXTENSION_CALL",
		description: "N/a"
	}
];

/**
 * AgentSettingsModal
 * @constructor
 */
const AgentSettingsModal = () => {

	const [showAgentSettingsModal, setShowAgentSettingsModal] = useState(false);

	const agentOptions = useSelector((state: any) => state.agentOptions);

	/**
	 * Handle Close -- func
	 */
	const handleClose = () => {
		setShowAgentSettingsModal(false);
	};

	/**
	 * Show Modal Handler -- func
	 * @param show
	 */
	const showModalHandler = (show: boolean) => {
		if(show){
			//Request data...
			postMessage({ request: "AgentOptions" });
		}
		setShowAgentSettingsModal(show);
	};

	/**
	 * Save changes action handler -- func
	 */
	const saveChanges = (cloneOptions: any = undefined) => {
		//Log
		console.log("[INFO] EVENT - saveChanges", {
			agentOptions: agentOptions,
			cloneOptions: cloneOptions
		});
		//..
		if(cloneOptions === undefined) {
			console.log("[INFO] EVENT - saveChanges agentOptions");
			postMessage({ action: "UpdateAgentOptions", value: {options: agentOptions.options}});
		} else {
			console.log("[INFO] EVENT - saveChanges cloneOptions");
			postMessage({ action: "UpdateAgentOptions", value: {options: cloneOptions.options}});
		}
	}

	/**
	 * Toggle user change -- func
	 * @param value
	 */
	const toggleChange = (value:string) => {
		console.log("[INFO] EVENT - toggleChange", {
			value: value,
			options: agentOptions.options
		});

		const index = agentOptions.options.indexOf(value);
		let cloneOptions : any = [];
		if (index > -1) { // only splice array when item is found
			//remove it
			console.log("[INFO] EVENT - toggleChange | agentOptions.options REMOVE", agentOptions.options);
			//Extract agent options
			let options = agentOptions.options;
			//Create new cloned array
			cloneOptions = [...options];
			//Remove 1 item, at index
			cloneOptions.splice(index, 1);
			//Log
			console.log("[INFO] EVENT - toggleChange | agentOptions.options UPDATED", cloneOptions);
			//Create new agent options array
			cloneOptions = {options: [...cloneOptions], userFilter: null};
		} else { //add it
			//Log
			console.log("[INFO] EVENT - toggleChange | agentOptions.options ADD", agentOptions.options);
			//Create new agent options array
			cloneOptions = {options: [value, ...agentOptions.options], userFilter: null};
			//Log
			console.log("[INFO] EVENT - toggleChange | agentOptions.options ADD ARRAY", cloneOptions);
		}
		//Store in redux
		setAgentOptions(cloneOptions);
		//Save changes...
		saveChanges(cloneOptions);
	}

	//Init
	useEffect(() => {
		eventEmitter.on("showAgentSettings", showModalHandler);

		return () => {
			eventEmitter.off("showAgentSettings", showModalHandler);
		};
	}, []);

	//
	useEffect(() => {
		//Log
		console.log("[INFO] EVENT - Agent Settings Updated!", {
			agentOptions: agentOptions
		});
	}, [agentOptions]);

	/**
	 * Save change handler -- func
	 * @param e
	 */
	const saveChange = (e:any)  => {
		//Log
		console.log("[INFO] EVENT - Toggle Clicked!");
		//Toggle UI and submit change
		toggleChange(e.target.value)
	}

	//Return
	return agentOptions && (
		<>
			<Modal centered onHide={handleClose} show={showAgentSettingsModal}>
				<Modal.Header className="bg-organization-gradient text-white" closeButton>
					<Modal.Title>Agent Settings</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Agent Settings</p>
					<SettingsCheckboxContainer>
						<form>
							{features.map(function (item: any, i: any) {
								return <div key={item.name} className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<div className="checkbox-wrapper" style={{margin: 10}}>
											<input
												className="checkbox"
												defaultChecked={agentOptions && agentOptions.options.find((s: string) => s === item.name)}
												id={"gridCheckTool" + item.name}
												/*onClick={saveChange}*/
												onChange={(e) => {
													if (e.target.checked) {
														saveChange(e)
													} else {
														saveChange(e)
													}
												}}
												type="checkbox"
												value={item.name}
											/>
											<label className="checkbox-label" htmlFor={"gridCheckTool" + item.name}>
												<i className="fas fa-check"></i>
												<i className="fas fa-times"></i>
												<span className="ball"></span>
											</label>
										</div>
									</div>
									<div className="flex-grow-1 ms-3">
										<legend
											className={(agentOptions && agentOptions.options.find((s: string) => s === item.name)) ? "col-form-label col-form-label-sm col-sm-12 pt-0 text-success fw-bold" : "col-form-label col-form-label-sm col-sm-12 pt-0"} data-bs-placement="bottom" data-bs-title={item.description}
											data-bs-toggle="tooltip"
											style={{
												textTransform: "capitalize",
												fontWeight: "bold",
												textAlign: "left"
											}}>{item.name.replace(/_/g, " ")}</legend>
									</div>
								</div>
							})}
						</form>
					</SettingsCheckboxContainer>
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={() => {
							//..
							handleClose();
						}}
						variant="secondary">
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

/**
 * StyledComponent
 */
const SettingsCheckboxContainer = styled.div<{}>`

	& {
		text-align: center;
	}

	& img {
		width: 100%;
		height: auto;
	}

	@media screen and (min-width: 620px) {
		& img {
			width: auto;
		}
	}

	.checkbox {
		opacity: 0;
		position: absolute;
	}

	.checkbox-label {
		background-color: #111;
		width: 50px;
		height: 26px;
		border-radius: 50px;
		position: relative;
		padding: 5px;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.fa-check {
		color: #54ac00;
	}

	.fa-times {
		color: #f50000;
	}

	.checkbox-label .ball {
		background-color: #fff;
		width: 22px;
		height: 22px;
		position: absolute;
		left: 2px;
		top: 2px;
		border-radius: 50%;
		transition: transform 0.2s linear;
	}

	.checkbox:checked + .checkbox-label .ball {
		transform: translateX(24px);
	}

	position: sticky;
	top: 0;
	z-index: 6;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	.settings-checkbox-wrapper {
		display: flex;
		justify-content: center;
	}

	& > * {
		flex: 1;
	}
`;

export default AgentSettingsModal;