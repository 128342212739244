import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { eventEmitter, getName } from "../../utils";

/**
 * FollowUpList
 * @constructor
 */
export default function FollowUpList() {
	const [ready, setReady] = useState(false);
	const [followUpList, setFollowUpList] = useState([]);
	const [failedDials, setFailedDials] = useState<any>([]);

	//Init...
	useEffect(() => {
		const setFollowUp = ({ ready, list, FailedDial }: { ready: any; list: any; FailedDial: any }) => {
			setReady(ready);
			setFollowUpList(list);

			if (!ready) {
				setFailedDials([]);
			} else if (FailedDial) {
				setFailedDials((prev: any) => [...prev, FailedDial]);
			}
		};

		eventEmitter.on("followUpReady", setFollowUp);

		return () => {
			eventEmitter.off("followUpReady", setFollowUp);
		};
	}, []);

	//Return
	return ready ? (
		<FollowUpListContainer>
			Follow-Up List ({followUpList.length} remaining)
			<div style={{ width: "100%" }}>
				{failedDials.map((item: any) => (
					<FollowUpListItem key={item.id}>
						<span>
							{getName(item)}
						</span>{" "}
						<span>Failed</span>
					</FollowUpListItem>
				))}
				{followUpList.slice(0, 3).map((item: any, i: number) => {
					const name = getName(item);

					return (
						<FollowUpListItem key={item.id}>
							{i === 0 ? <strong>{name}</strong> : <span>{name}</span>}{" "}
							<span style={{ fontWeight: i === 0 ? "bold" : "normal" }}>{i === 0 ? "On-Call" : "Up Next"}</span>
						</FollowUpListItem>
					);
				})}
			</div>
		</FollowUpListContainer>
	) : (
		<></>
	);
}

/**
 * StyledComponent
 */
const FollowUpListItem = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 5px;
`;

/**
 * StyledComponent
 */
const FollowUpListContainer = styled.div`
	display: flex;
	row-gap: 10px;
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
`;