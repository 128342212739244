import React, {
	//useState,
	useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setHotlist, setReadyFollowUp } from "../../redux/globalSlice";
import {
	eventEmitter,
	formatPhoneToDigitsOnly,
	getValidHubspotPhone,
	isCallableLead,
	postMessage,
	sortHotlistByDateFurthestOut,
} from "../../utils";

export default function ReadyFollowUp() {
	const hotlist = useSelector((state: any) => state.hotlist);
	const agentType = useSelector((state: any) => state.agentType);
	const readyState = useSelector((state: any) => state.readyState);
	const isReady = readyState?.currentState?.readyChannels?.length > 0;
	const callStatus = useSelector((state: any) => state.callStatus);
	const dispatch = useDispatch();

	//Init...
	useEffect(() => {
		const callNext = (data: any) => {
			while (true) {
				const list = sortHotlistByDateFurthestOut(hotlist).filter(isCallableLead);

				const obj: any = { ready: true, list: list };

				if (data.FailedDial) {
					obj.FailedDial = list.find((item) => getValidHubspotPhone(item) === data.FailedDial);

					list.shift();
				}

				if (list.length === 0) {
					postMessage({ action: "SetFollowUpProcess", value: false });
					eventEmitter.emit("followUpReady", { ready: false, list: [] });
					dispatch(setReadyFollowUp(false));
					break;
				}

				const nextCall = list[0];

				const newHotlist = hotlist.map((item: any) => {
					let newItem = { ...item };

					if (newItem.id === nextCall.id) {
						newItem.called = true;
					}

					if (newItem.id === obj.FailedDial?.id) {
						newItem.called = true;
						newItem = {
							...newItem,
							properties: {
								...newItem.properties,
								five9_last_dial_start_timestamp: new Date().toISOString().replace("Z", ""),
							},
						};
					}

					return newItem;
				});

				dispatch(setHotlist(newHotlist));

				eventEmitter.emit("followUpReady", obj);

				const phone = getValidHubspotPhone(nextCall);

				if (phone) {
					setTimeout(() => {
						postMessage({
							action: "DialNumber",
							value: { number: formatPhoneToDigitsOnly(phone), campaignId: "300000000000736" },
						});
					}, 2000);
					break;
				} else {
					continue;
				}
			}
		};

		eventEmitter.on("followUpProcessCallFinished", callNext);

		return () => {
			eventEmitter.off("followUpProcessCallFinished", callNext);
		};
	}, [dispatch, hotlist]);

	/**
	 * Set Ready -- func
	 */
	const setReady = () => {
		if (isReady || callStatus === "ONCALL" || callStatus === "WRAPUP") {
			toast.error("Please switch to Not Ready state and ensure there is no ongoing call.");
			return;
		}

		dispatch(setReadyFollowUp(true));
		const list = sortHotlistByDateFurthestOut(hotlist).filter(isCallableLead);

		if (list.length === 0) {
			return;
		}

		const item = list[0];

		const newHotlist = hotlist.map((lead: any) => {
			const newItem = { ...lead };

			if (item.id === newItem.id) {
				newItem.called = true;
			}

			return newItem;
		});

		dispatch(setHotlist(newHotlist));

		const phone = getValidHubspotPhone(item);

		postMessage({ action: "SetFollowUpProcess", value: true });
		postMessage({
			action: "DialNumber",
			value: { number: formatPhoneToDigitsOnly(phone), campaignId: "300000000000736" },
		});
		eventEmitter.emit("followUpReady", { ready: true, list });
	};

	//Return
	return agentType === "LO" ? (
		<>
			<Dropdown.Item disabled={!hotlist || hotlist?.length === 0} onClick={setReady}>
				Ready - Follow Up
			</Dropdown.Item>
		</>
	) : (
		<></>
	);
}