import React, { useEffect, useState } from "react";
import { Alert, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setReadyFollowUp } from "../redux/globalSlice";
import { eventEmitter, notReadyStatePayload, postMessage, readyStatePayload } from "../utils";
import ReadyFollowUp from "./LO/ReadyFollowUp";

/**
 * ReadyState
 * @constructor
 */
export default function ReadyState() {
	const readyState = useSelector((state: any) => state.readyState);
	const callStatus = useSelector((state: any) => state.callStatus);
	const isReady = readyState?.currentState?.readyChannels?.length > 0;
	const [delay, setDelay] = useState(0);
	const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
	const readyFollowUp = useSelector((state: any) => state.readyFollowUp);
	const dispatch = useDispatch();

	//Init...
	useEffect(() => {
		if (typeof readyState?.pendingStateDelayTime === "number") {
			clearInterval(intervalId);
			const secs = Math.floor(readyState.pendingStateDelayTime / 1000);
			setDelay(secs);

			if (secs > 0) {
				setIntervalId(
					setInterval(() => {
						setDelay((prev) => prev - 1);
					}, 1000)
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readyState?.pendingStateDelayTime]);

	if (intervalId !== undefined && delay === 0) {
		clearInterval(intervalId);
		setIntervalId(undefined);
	}

	let callStatusDisplay;
	let callStatusVariant;

	if (callStatus === "WRAPUP") {
		callStatusDisplay = "Wrap Up";
		callStatusVariant = "warning";
	} else if (callStatus === "ONCALL") {
		callStatusDisplay = "On Call";
		callStatusVariant = "primary";
	} else if (readyFollowUp) {
		callStatusDisplay = "Ready - Follow Up";
		callStatusVariant = "info";
	} else if (isReady) {
		callStatusDisplay = "Ready - New Leads";
		callStatusVariant = "success";
	} else {
		callStatusDisplay = "Not Ready";
		callStatusVariant = "danger";
	}

	//Return
	return readyState ? (
		<>
			<Dropdown>
				<Dropdown.Toggle style={{ width: "100%", borderRadius: 0 }} variant={callStatusVariant}>
					{callStatusDisplay}
				</Dropdown.Toggle>
				<Dropdown.Menu style={{ width: "100%" }}>
					<Dropdown.Item
						onClick={() => {
							dispatch(setReadyFollowUp(false));
							setReadyFollowUp(false);
							postMessage({ action: "SetFollowUpProcess", value: false });
							eventEmitter.emit("followUpReady", { ready: false, list: [] });
							postMessage({
								action: "ChangeReadyState",
								value: readyStatePayload(),
							})
						}}>
						Ready - New Leads
					</Dropdown.Item>
					<ReadyFollowUp />
					<Dropdown.Item disabled>Ready - SharkTank <sup>(Coming Soon)</sup></Dropdown.Item>
					<Dropdown.Item onClick={() => {
						dispatch(setReadyFollowUp(false));
						setReadyFollowUp(false);
						postMessage({ action: "SetFollowUpProcess", value: false });
						eventEmitter.emit("followUpReady", { ready: false, list: [] });
						postMessage({ action: "ChangeReadyState", value: notReadyStatePayload() })
					}}>
						Not Ready
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			{readyState.pendingState && (
				<Alert style={{ fontSize: 14, padding: 10 }} variant="warning">
					Pending State: {readyState.pendingState.readyChannels.length > 0 ? "Ready" : `Not Ready`}{" "}
					{intervalId !== undefined && `(00:${delay > 9 ? delay : `0${delay}`})`}
				</Alert>
			)}
		</>
	) : (
		<></>
	);
}
