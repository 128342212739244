import React, { useState, useEffect, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import CallForwardingModal from "./modals/CallForwardingModal";
import { useDispatch, useSelector } from "react-redux";
import { setAppTheme } from "../redux/globalSlice";
import {eventEmitter} from "../utils";

/**
 * Settings
 * @constructor
 */
export default function Settings() {
	const callForwardModalState = useState(false);
	const theme = useSelector((state: any) => state.theme);
	const agentType = useSelector((state: any) => state.agentType);
	const dispatch = useDispatch();

	const getStoredTheme = () => localStorage.getItem("theme");

	const setStoredTheme = (theme: string) => localStorage.setItem("theme", theme);

	/**
	 * Set Theme -- func
	 */
	const setTheme = useCallback((theme: string) => {
		dispatch(setAppTheme(theme));
		if (theme === "auto") {
			document.documentElement.setAttribute(
				"data-bs-theme",
				window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
			);
		} else {
			document.documentElement.setAttribute("data-bs-theme", theme);
			const header = document.querySelector("header");

			if (header) {
				header.style.backgroundColor = theme === "dark" ? "#363837" : "#f0f0f0";
			}
		}
	}, [dispatch]);

	/**
	 * Get Preferred Theme - func
	 */
	const getPreferredTheme = useCallback(() => {
		const storedTheme = getStoredTheme();

		if (storedTheme) {
			return storedTheme;
		}

		const theme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
		setStoredTheme(theme);

		return theme;
	}, []);

	//Init
	useEffect(() => {
		setTheme(getPreferredTheme());
	}, [dispatch, getPreferredTheme, setTheme]);

	//Return
	return (
		<>
			<Dropdown>
				<Dropdown.Toggle size="sm" variant={theme === "dark" ? "outline-light" : "outline-dark"}>
					<i className="fa-solid fa-gear"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<div className="checkbox-wrapper" style={{ marginBottom: 10 }}>
						<input
							checked={getPreferredTheme() !== "light"}
							className="checkbox"
							id="checkbox"
							onChange={(e) => {
								if (e.target.checked) {
									setTheme("dark");
									setStoredTheme("dark");
								} else {
									setTheme("light");
									setStoredTheme("light");
								}
							}}
							type="checkbox"
						/>
						<label className="checkbox-label" htmlFor="checkbox">
							<i className="fas fa-moon"></i>
							<i className="fas fa-sun"></i>
							<span className="ball"></span>
						</label>
					</div>
					<Dropdown.Item disabled={agentType !== "LO"} onClick={() => callForwardModalState?.[1](true)}>
						Call Forwarding
					</Dropdown.Item>
					<Dropdown.Item onClick={() => eventEmitter.emit("showAgentSettings", {})}>
						Open Hub Settings
					</Dropdown.Item>
					<Dropdown.Item onClick={() => eventEmitter.emit("showFive9AgentSettings", {})}>
						Open Five9 Settings
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<CallForwardingModal callForwardModalState={callForwardModalState} />
		</>
	);
}