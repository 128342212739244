import React, { useState, useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const Leaderboards: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const agent = useSelector((state: any) => state.agent);
	const ccsStats = useSelector((state: any) => state.ccsStats);
	const [totalTransfersToday, setTotalTransfersToday] = useState(0);

	const getTrophy = (i: number) => {
		switch (i) {
			case 0:
				return (
					<>
						<i className="fa-solid fa-trophy fa-flip" style={{ color: "gold" }}></i>&nbsp;
					</>
				);
			case 1:
				return (
					<>
						<i className="fa-solid fa-trophy " style={{ color: "silver" }}></i>&nbsp;
					</>
				);
			case 2:
				return (
					<>
						<i className="fa-solid fa-trophy " style={{ color: "#CD7F32" }}></i>&nbsp;
					</>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (ccsStats?.Rankings?.length > 0) {
			let total = 0;
			ccsStats.Rankings.forEach((metric: any, i: number) => {
				const refiValue = metric.SuccessRefinance ?? 0;
				const purchaseValue = metric.SuccessPurchase ?? 0;
				const louValue = metric.LOU ?? 0;

				total += refiValue + purchaseValue + louValue;
			});

			setTotalTransfersToday(total);
		}
	}, [ccsStats?.Rankings]);

	return ccsStats?.Rankings?.length > 0 ? (
		<div>
			<Button onClick={() => setShowModal(true)} size="sm" variant="link">
				Leaderboard
			</Button>
			<Modal centered onHide={() => setShowModal(false)} show={showModal}>
				<Modal.Header className={"bg-organization-gradient text-white fw-bolder"} closeButton>
					<Modal.Title>CCS Leaderboard</Modal.Title>
				</Modal.Header>
				<div style={{padding: '10px 20px', background: "#066d8a"}}>
					<div style={{
						margin: "0 auto",
						textAlign: "center",
						textTransform: "uppercase",
						fontWeight: 'bolder',
						padding: "8px 15px",
						width: '100%',
						opacity: "80",
						border: "1px solid #DDD",
						color:"#FFF",
						letterSpacing:"0.5px"
					}}>
						<h2>

							<span className={"badge rounded-pill bg-organization-gradient fw-bolder"}>{totalTransfersToday}</span>
							<br />
							Leaderboard
						</h2>


		</div>
</div>
	<Modal.Body style={{background: "#066d8a"}}>
					<Container className={"text-white"}>
						<Row style={{marginBottom: 5, background: "rgba(0,20,44,0.37)"}}>
							<Col xs={6}>
								<strong>Agent</strong>
							</Col>
							<Col style={{textAlign: "right"}} xs={6}>
								<strong>Transfers</strong>
							</Col>
						</Row>
						{ccsStats.Rankings.map((metric: any, i: number) => {
							const refiValue = metric.SuccessRefinance ?? 0;
							const purchaseValue = metric.SuccessPurchase ?? 0;
							const louValue = metric.LOU ?? 0;

							return (
								<Row key={metric.Agent} style={{marginTop: 5, background: "rgba(0,20,44," + (0.37 * ((i + 1) * 0.5)) + ")"}}>
									<Col xs={6}>
										<span style={{"width": "100px"}}>
											<span
												className={"badge bg-organization-gradient fw-bolder rounded-pill"}>{i + 1}</span>
										</span>
										{getTrophy(i)}{" "}
										{i > -1 && i < 3 ? (
											<strong
												style={{
													textDecoration: metric.Agent === (agent?.fullName || agent?.full_name) ? "underline" : undefined,
												}}>
												{metric.Agent}
											</strong>
										) : (
											<span
												style={{
													textDecoration: metric.Agent === (agent?.fullName || agent?.full_name) ? "underline" : undefined,
												}}>
												{metric.Agent}
											</span>
										)}
									</Col>
									<Col style={{textAlign: "right"}} xs={6}>
										{refiValue + purchaseValue + louValue}
									</Col>
								</Row>
							);
						})}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setShowModal(false)} variant="secondary">
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	) : (
		// TODO  WHAT IS THIS DOING
		<div/>
	);
};

/**
 * StyledComponent
 */
export default Leaderboards;