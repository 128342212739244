import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import { eventEmitter } from "../utils";
import ActionButtons from "./ActionButtons";
import Conference from "./Conference";
import Dialer from "./Dialer";
import FollowUpList from "./LO/FollowUpList";
import ReadyState from "./ReadyState";

/**
 * Sidebar
 * @param adtBarHeight
 * @constructor
 */
export default function Sidebar({ adtBarHeight }: {adtBarHeight: number}) {
    const theme = useSelector((state: any) => state.theme);
    const [followUpReady, setFollowUpReady] = useState(false);

    //Init...
    useEffect(() => {
        eventEmitter.on("followUpReady", ({ ready }) => {
            setFollowUpReady(ready);
        });
    }, []);
    
    return (
        <SidebarContainer $adtBarHeight={adtBarHeight} $theme={theme}>
            <ReadyState />
            <FollowUpList />
            {!followUpReady && <Dialer />}
            <Conference />
            <ActionButtons />
        </SidebarContainer>
    )
}

/**
 * StyledComponent
 */
const SidebarContainer = styled.div<{ $theme?: string, $adtBarHeight: number }>`
    align-self: stretch;
    position: sticky;
    top: ${props => props.$adtBarHeight}px;
    height: calc(100vh - ${props => props.$adtBarHeight}px);
    overflow: auto;
    background-color: ${props => props.$theme === "dark" ? "#48494a" : "#d4d7d9"};
    width: 250px;
`