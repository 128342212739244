import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "suneditor/dist/css/suneditor.min.css";
import { createGlobalStyle } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-circular-progressbar/dist/styles.css';

/**
 *
 */
const GlobalStyles = createGlobalStyle`
	html, body, #root {
		height: 100%;
		overflow: hidden;
	}

	h1 {
		font-size: 25px;
	}

	h2 {
		font-size: 20px;
	}

	main {
		display: flex;
		row-gap: 20px;
		padding: 20px;
	}
`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<>
		<GlobalStyles />
		<Provider store={store}>
			<ToastContainer
				autoClose={5000}
				closeOnClick
				draggable
				hideProgressBar={false}
				newestOnTop={false}
				pauseOnFocusLoss
				pauseOnHover
				position="bottom-right"
				rtl={false}
				theme="colored"
			/>
			<App />
		</Provider>
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
