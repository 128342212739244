import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import { postMessage } from "../../utils";
import config from "../../config.json";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Leaderboards from "./Leaderboards";

/**
 * Stats
 * @constructor
 */
export default function Stats() {
	const agentType = useSelector((state: any) => state.agentType);
	const ccsStats = useSelector((state: any) => state.ccsStats);
	const [lastRefresh, setLastRefresh] = useState<Date | null>(new Date());

	const refiValue = ccsStats?.Agent?.SuccessRefinance ?? 0;
	const purchaseValue = ccsStats?.Agent?.SuccessPurchase ?? 0;
	const louValue = ccsStats?.Agent?.LOU ?? 0;

	//Init...
	useEffect(() => {
		let intervalId = setInterval(() => {
			postMessage({ request: "CcsStats" });
			setLastRefresh(new Date());
		}, config.metricRefresh.CCS);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	//Return
	return agentType === "CCS" && ccsStats ? (
		<StatsContainer>
			<div>
				<OverlayTrigger
					overlay={
						<Tooltip className="in" placement="bottom">
							Data refreshes every 2 minutes. Last refresh: {lastRefresh?.toLocaleString()}
						</Tooltip>
					}
					placement="bottom">
					<div>
						<strong>Today&apos;s Transfers</strong>
						<br />
						<span style={{ color: "lightgreen", WebkitTextStrokeColor: "black", WebkitTextStrokeWidth: 0.5 }}>
							{refiValue + purchaseValue + louValue}
						</span>
					</div>
				</OverlayTrigger>
				<Leaderboards />
			</div>
		</StatsContainer>
	) : (
		<></>
	);
}

/**
 * StyledComponent
 */
const StatsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	column-gap: 20px;
`;
