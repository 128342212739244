import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import config from "../../config.json";
import Leaderboards from "./Leaderboards";

/**
 * Stats
 * @constructor
 */
export default function Stats() {
	const agentType = useSelector((state: any) => state.agentType);
	const loStats = useSelector((state: any) => state.loStats);

	//Init...
	useEffect(() => {
		let intervalId = setInterval(() => {
			postMessage({ request: "LoStats" });
		}, config.metricRefresh.LO);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	/**
	 * Get Trophy -- func
	 * @param i
	 */
	const getTrophy = (i: number) => {
		switch (i) {
			case 0:
				return <><i className="fa-solid fa-trophy" style={{ color: "gold" }}></i>&nbsp;</>;
			case 1:
				return <><i className="fa-solid fa-trophy" style={{ color: "silver" }}></i>&nbsp;</>;
			case 2:
				return <><i className="fa-solid fa-trophy" style={{ color: "#CD7F32" }}></i>&nbsp;</>;
			default:
				return null;
		}
	};

	/**
	 * Sliced ESigns -- func
	 */
	const slicedESigns = loStats?.ESigns?.length > 2 ? loStats.ESigns.slice(0, 3) : loStats?.ESigns;

	//Return
	return agentType === "LO" && loStats?.ESigns?.length > 0 ? (
		<StatsContainer>
			<div>
				<StatsHeaderContainer>
					{loStats.LastRefreshed ? (
						<OverlayTrigger
							overlay={
								<Tooltip className="in" placement="bottom">
									Last refresh: {new Date(loStats.LastRefreshed).toLocaleString()}
								</Tooltip>
							}
							placement="bottom">
							<strong>E-Sign Leaderboard - Last 7 Days</strong>
						</OverlayTrigger>
					) : (
						<strong>E-Sign Leaderboard - Last 7 Days</strong>
					)}
					<Leaderboards />
				</StatsHeaderContainer>
				<MetricContainer>
					{slicedESigns?.map((metric: any, i: number) => (
						<Metric key={metric.LoanOfficer}>
							{getTrophy(i)}<strong>{metric.LoanOfficer}</strong>
							&nbsp;-&nbsp;{metric.ESigns}&nbsp;-&nbsp;
							<span style={{ color: "lightgreen", WebkitTextStrokeColor: "black", WebkitTextStrokeWidth: 0.5 }}>
								${Number(metric.TotalCompensation).toLocaleString()}
							</span>
						</Metric>
					))}
				</MetricContainer>
			</div>
			<div />
		</StatsContainer>
	) : (
		<></>
	);
}

/**
 * StyledComponent
 */
const StatsHeaderContainer = styled.div`
	display: flex; 
	justify-content: center;
	align-items: center;
`

/**
 * StyledComponent
 */
const StatsContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
`;

/**
 * StyledComponent
 */
const MetricContainer = styled.div({
	marginTop: 10,
	display: "flex",
	justifyContent: "center",
	columnGap: 50,
});

/**
 * StyledComponent
 */
const Metric = styled.div``;