import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CallbackToast from "../LO/CallbackToast";

/**
 * Use Hotlist Reminders
 */
export default function useHotlistReminders() {
    const hotlist = useSelector((state: any) => state.hotlist);
    const hotlistRef = useRef(hotlist);
    const [initialLoad, setInitialLoad] = useState(false);

    /**
     * Check Hot-list -- func
     */
    const checkHotlist = () => {
        if (hotlistRef.current?.length > 0) {
            hotlistRef.current.forEach((item: any) => {
                const callbackTime = new Date(item.properties.callback_appointment_ts);
                const currentTime = new Date();
                const timeDifference = callbackTime.getTime() - currentTime.getTime();
                const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    
                if (minutesDifference <= 5 && minutesDifference >= 0) {
                    toast.warning(<CallbackToast minutes={minutesDifference} properties={item.properties} />, {
                        autoClose: false,
                        hideProgressBar: true,
                    });
                }
            })
        }
    }

    //Init...
    useEffect(() => {
        hotlistRef.current = hotlist;
    }, [hotlist])

    useEffect(() => {
        if (!initialLoad && hotlist?.length > 0) {
            checkHotlist();
            setInitialLoad(true);
        }
    }, [hotlist?.length, initialLoad])

    useEffect(() => {
        const interval = setInterval(checkHotlist, 60000);

        return () => clearInterval(interval);
    }, [])
}