import moment from "moment";
import momenttz from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setCustomer } from "../redux/globalSlice";
import { eventEmitter, formatPhoneNumber, getAbbrStates, postMessage } from "../utils";
import useContactFields, { FieldKeys } from "./hooks/useContactFields";
import useLoanApplication from "./hooks/useLoanApplication";
import InterestRateModal from "./modals/InterestRateModal";
import zillow from "../assets/zillow.png";

/**
 * ClientData
 * @constructor
 */
export default function ClientData() {
	const customer = useSelector((state: any) => state.customer);
	const agentType = useSelector((state: any) => state.agentType);
	const clientWeather = useSelector((state: any) => state.clientWeather);
	const weather = clientWeather?.current;
	const dispatch = useDispatch();
	const fieldsToInclude = useContactFields();
	const { validated, submitLoanApplication } = useLoanApplication();
	const theme = useSelector((state: any) => state.theme);
	const [ssn, setSsn] = useState<any>();
	const [retrievingSsn, setRetrievingSsn] = useState(false);
	const [hideSsn, setHideSsn] = useState(true);

	/**
	 * Set Value -- func
	 * @param prop
	 * @param value
	 */
	const setValue = (prop: FieldKeys, value: string) => {
		if (value === customer?.rawContactObj?.fields?.[prop]) {
			return;
		}

		const updatedCustomer = customer ? structuredClone(customer) : undefined;

		if (updatedCustomer?.rawContactObj?.fields) {
			updatedCustomer.rawContactObj.fields[prop] = value;
		}

		dispatch(setCustomer(updatedCustomer));
		postMessage({ action: "SaveContact", value: updatedCustomer.rawContactObj });
	};

	/**
	 * On Key Down -- func
	 * @param e
	 */
	const onKeyDown = (e: React.KeyboardEvent<any>) => {
		if (e.key === "Enter") {
			e.currentTarget.blur();
		}
	};

	/**
	 * Disable Select Key Events -- func
	 * @param e
	 */
	const disableSelectKeyEvents = (e: React.KeyboardEvent<any>) => {
		if (e.key !== "Tab") {
			e.preventDefault();
		}
	};

	/**
	 * Render Field -- func
	 * @param key
	 * @param field
	 */
	const renderField = (key: FieldKeys, field: any) => {
		if (!field.editable) {
			let displayText = customer?.[field.prop];

			// don't display fields that have no value, starts with unknown, or has a value of 0
			if (
				!displayText ||
				(displayText && !isNaN(displayText as any) && Number(displayText) === 0) ||
				(typeof displayText === "string" && displayText.startsWith("(Unknown"))
			) {
				return <React.Fragment key={field.label} />;
			}

			// format number fields to include comma delimitation if not zip code
			if (displayText && !isNaN(displayText as any) && field.label !== "Zip" && field.label !== "Primary Phone") {
				displayText = Number(displayText).toLocaleString();
			}

			if (field.label === "Primary Phone") {
				displayText = formatPhoneNumber(displayText);
			}

			if (field.label === "Date of Birth") {
				displayText = moment(displayText).format("MM/DD/YYYY");
			}

			if (field.label === "Recording Date") {
				displayText = moment(displayText).format("MM/DD/YYYY");
			}

			// only display date value for event date
			if (displayText && field.label === "Event Date") {
				displayText = moment(displayText.split(" ")[0]).format("MM/DD/YYYY");
			}

			return (
				<Form.Group key={field.label} as={Row} style={{ alignItems: "center", wordBreak: "break-all", padding: "2px 0" }}>
					<Form.Label column style={{ textAlign: "right", padding: "0 2px" }} xs={6}>
						<OverlayTrigger
							overlay={
								<Tooltip className="in" placement="top">
									{displayText}
								</Tooltip>
							}
							placement="top">
							<span>{field.label}</span>
						</OverlayTrigger>
					</Form.Label>
					<Col style={{ textAlign: "left" }} xs={6}>
						<strong>{displayText}</strong>
					</Col>
				</Form.Group>
			);
		}

		if (field.label === "State") {
			return (
				<Form.Group key={field.label} as={Row} style={{ alignItems: "center" }}>
					<Form.Label column style={{ textAlign: "right", padding: "0 5px" }} xs={6}>
						<OverlayTrigger
							overlay={
								<Tooltip className="in" placement="top">
									{customer?.rawContactObj?.fields?.[key]}
								</Tooltip>
							}
							placement="top">
							<span>{field.label}</span>
						</OverlayTrigger>
					</Form.Label>
					<Col xs={6}>
						<Form.Select
							disabled={!field.editable}
							onChange={(e) => setValue(key, e.target.value)}
							onKeyDown={disableSelectKeyEvents}
							style={{ fontWeight: "bold" }}
							value={customer?.rawContactObj?.fields?.[key]}>
							{getAbbrStates().map((state) => (
								<option key={state} value={state}>
									{state}
								</option>
							))}
						</Form.Select>
					</Col>
				</Form.Group>
			);
		}

		if (field.label === "Loan Purpose") {
			const loanPurposeValue = customer?.rawContactObj?.fields?.[key];
			return (
				<Form.Group key={field.label} as={Row} style={{ alignItems: "center" }}>
					<Form.Label column style={{ textAlign: "right", padding: "0 5px" }} xs={6}>
						<OverlayTrigger
							overlay={
								<Tooltip className="in" placement="top">
									{loanPurposeValue}
								</Tooltip>
							}
							placement="top">
							<span>
								{field.label} <Required />
							</span>
						</OverlayTrigger>
					</Form.Label>
					<Col xs={6}>
						<Form.Select
							disabled={!field.editable}
							onChange={(e) => setValue(key, e.target.value)}
							onKeyDown={disableSelectKeyEvents}
							required
							style={{ fontWeight: "bold" }}
							value={["Refinance", "Purchase"].includes(loanPurposeValue) ? loanPurposeValue : ""}>
							<option value="">Select Loan Purpose</option>
							<option value="Refinance">Refinance</option>
							<option value="Purchase">Purchase</option>
						</Form.Select>
						{key === FieldKeys.LOAN_PURPOSE && (
							<Form.Control.Feedback type="invalid">Loan purpose must be Refinance or Purchase</Form.Control.Feedback>
						)}
					</Col>
				</Form.Group>
			);
		}

		const requiredFields = [FieldKeys.FIRST_NAME, FieldKeys.LAST_NAME, FieldKeys.EMAIL, FieldKeys.LOAN_PURPOSE];
		const isRequired = requiredFields.includes(key);

		return (
			<Form.Group key={field.label} as={Row} style={{ alignItems: isRequired ? "flex-start" : "center" }}>
				<Form.Label column style={{ textAlign: "right", padding: "0 5px" }} xs={6}>
					<OverlayTrigger
						overlay={
							<Tooltip className="in" placement="top">
								{customer?.rawContactObj?.fields?.[key]}
							</Tooltip>
						}
						placement="top">
						<span>
							{field.label} {isRequired && <Required />}
						</span>
					</OverlayTrigger>
				</Form.Label>
				<Col xs={6}>
					<Form.Control
						defaultValue={customer?.rawContactObj?.fields?.[key]}
						disabled={!field.editable}
						onBlur={(e) => setValue(key, e.target.value)}
						onKeyDown={onKeyDown}
						required={isRequired}
						style={{ fontWeight: "bold" }}
						type={key === FieldKeys.EMAIL ? "email" : "text"}
					/>
					{key === FieldKeys.EMAIL && (
						<Form.Control.Feedback type="invalid">
							Please provide a valid email in the form of &quot;sample@sample.com&quot;
						</Form.Control.Feedback>
					)}
					{key === FieldKeys.FIRST_NAME && (
						<Form.Control.Feedback type="invalid">Please provide a first name</Form.Control.Feedback>
					)}
					{key === FieldKeys.LAST_NAME && (
						<Form.Control.Feedback type="invalid">Please provide last name</Form.Control.Feedback>
					)}
				</Col>
			</Form.Group>
		);
	};

	/**
	 * Open Zillow - func
	 */
	const openZillow = () => {
		const address = [];

		if (customer?.rawContactObj?.fields?.[FieldKeys.STREET]) {
			address.push(customer?.rawContactObj?.fields?.[FieldKeys.STREET]);
		}

		if (customer?.rawContactObj?.fields?.[FieldKeys.CITY]) {
			address.push(customer?.rawContactObj?.fields?.[FieldKeys.CITY]);
		}

		if (customer?.rawContactObj?.fields?.[FieldKeys.STATE]) {
			address.push(customer?.rawContactObj?.fields?.[FieldKeys.STATE]);
		}

		if (customer?.rawContactObj?.fields?.[FieldKeys.ZIP]) {
			address.push(customer?.rawContactObj?.fields?.[FieldKeys.ZIP]);
		}

		const url = `https://www.zillow.com/homes/${address.join("-")}/`;
		window.open(url, "_blank");
	};

	/**
	 * Get SSN -- func
	 */
	const getSsn = () => {
		setHideSsn(prev => !prev);

		if (!ssn) {
			postMessage({ request: "GetSSN" });
			setRetrievingSsn(true);
		}

		if (hideSsn) {
			setTimeout(() => setHideSsn(true), 5000);
		}
	};

	//init..
	useEffect(() => {
		const handleSSN = (ssn4: any) => {
			setSsn(ssn4);
			setRetrievingSsn(false);
		};

		eventEmitter.on("ssn4", handleSSN);

		return () => {
			eventEmitter.off("ssn4", handleSSN);
		};
	}, []);

	useEffect(() => {
		if (!customer) {
			setRetrievingSsn(false);
			setHideSsn(true);
			setSsn(undefined);
		}
	}, [customer]);

	//Return
	return customer ? (
		<ClientDataContainer>
			<ClientDataHeader>
				<h1>Client Data</h1>
			</ClientDataHeader>
			<Form noValidate onSubmit={submitLoanApplication} validated={validated}>
				{fieldsToInclude &&
					Object.entries(fieldsToInclude).map(([key, value]) => renderField(key as unknown as FieldKeys, value))}
				{weather && (
					<>
						<Form.Group as={Row} style={{ alignItems: "center", wordBreak: "break-all", padding: "2px 0" }}>
							<Form.Label column style={{ textAlign: "right", padding: "0 2px" }} xs={6}>
								Client&apos;s Weather
							</Form.Label>
							<Col style={{ textAlign: "left" }} xs={6}>
								<WeatherContainer>
									<img height="40px" src={`https:${weather.condition?.icon}`} />
									<span>
										<strong>{weather.temp_f}&deg;F</strong>
									</span>
								</WeatherContainer>
							</Col>
						</Form.Group>
					</>
				)}
				{clientWeather?.location?.localtime && clientWeather.location.tz_id && (
					<Form.Group as={Row} style={{ alignItems: "center", wordBreak: "break-all", padding: "2px 0" }}>
						<Form.Label column style={{ textAlign: "right", padding: "0 2px" }} xs={6}>
							Client&apos;s Time
						</Form.Label>
						<Col style={{ textAlign: "left" }} xs={6}>
							<WeatherContainer>
								<span>
									<strong>
										{momenttz.tz(clientWeather.location.localtime, clientWeather.location.tz_id).format("hh:mm A z")}
									</strong>
								</span>
							</WeatherContainer>
						</Col>
					</Form.Group>
				)}
				{agentType === "LO" &&
					customer &&
					["In-Market-Credit-2", "In-Market-Credit-7", "In-Market-Credit-10"].includes(
						customer?.rawContactObj?.fields?.[FieldKeys.VENDOR]
					) && (
						<Form.Group as={Row} style={{ alignItems: "center", wordBreak: "break-all", padding: "2px 0" }}>
							<Form.Label column style={{ textAlign: "right", padding: "0 2px" }} xs={6}>
								Last 4 SSN
							</Form.Label>
							<Col style={{ textAlign: "left", alignItems: "center", display: "flex", columnGap: 10 }} xs={6}>
								{retrievingSsn ? "Retrieving..." : hideSsn ? "" : ssn}
								<Button
									disabled={retrievingSsn}
									onClick={getSsn}
									size="sm"
									variant={theme === "dark" ? "outline-light" : "outline-dark"}>
									{!hideSsn ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
								</Button>
							</Col>
						</Form.Group>
					)}
				<br />
				{agentType === "LO" && customer && (
					<div style={{ display: "flex", justifyContent: "center", columnGap: 10, flexWrap: "wrap", rowGap: 10 }}>
						<Button type="submit" variant="success">
							Create Loan Application
						</Button>
						<Button onClick={openZillow} style={{ display: "flex", alignItems: "center", columnGap: 5 }}>
							Zillow
							<img height={20} src={zillow} />
						</Button>
						<InterestRateModal />
					</div>
				)}
			</Form>
		</ClientDataContainer>
	) : (
		<></>
	);
}

/**
 * StyledComponent
 */
const WeatherContainer = styled.div`
	display: flex;
	align-items: center;
`;

/**
 * StyledComponent
 */
const ClientDataContainer = styled.div`
	.client-data-field {
		display: flex;
		column-gap: 10px;
		margin: 3px 0;
		align-items: center;
	}

	.client-data-field > * {
		text-align: right;
		flex: 1;
	}

	.client-data-field strong {
		text-align: left !important;
	}
`;

/**
 * StyledComponent
 */
const ClientDataHeader = styled.div({
	display: "flex",
	columnGap: 20,
	alignItems: "center",
	justifyContent: "center",
});

/**
 * StyledComponent
 */
const Required = styled.span`
	color: red;

	&::after {
		content: "*";
	}
`;
