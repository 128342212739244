//import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import ideasImg from "../../assets/ideas.png";
import { postMessage } from "../../utils";

/**
 * IdeasModal
 * @constructor
 */
export default function IdeasModal() {
	const [showModal, setShowModal] = useState(false);
	const [files, setFiles] = useState<FileList | null>();
	const [description, setDescription] = useState<string>();
	const [subject, setSubject] = useState<string>();
	const [type, setType] = useState<string>("Idea");

	/**
	 * Hide Modal -- func
	 */
	const hideModal = () => {
		setFiles(undefined);
		setDescription(undefined);
		setShowModal(false);
		setSubject(undefined);
	};

	/**
	 * Save Idea -- func
	 */
	async function saveIdea() {
		if (!description || !type || !subject) {
			toast.error("An idea type, subject, and description are required to submit");
			return;
		}

		if (files && (files.length > 5 || Array.from(files).some((file) => file.size > 20 * 1024 * 1024))) {
			toast.error("Only 5 files allowed, each at 20MB");
			return;
		}

		postMessage({ action: "SubmitIdea", value: { files, description, type, subject } });
		hideModal();
	}

	//Return
	return (
		<>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<img src={ideasImg} style={{ borderRadius: "50%", height: 30, width: "auto" }} />
				<Button onClick={() => setShowModal(true)} style={{ color: "inherit" }} variant="link">
					Have An Idea?
				</Button>
			</div>
			<Modal centered onHide={() => hideModal()} show={showModal} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>Your Idea</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Row>
							<Form.Group as={Col} sm={6} xs={12}>
								<Form.Label>Idea Type</Form.Label>
								<Form.Select onChange={(e) => setType(e.target.value)}>
									<option value="Idea">Idea</option>
									<option value="Sales Tip">Sales Tip</option>
									<option value="General Suggestion">General Suggestion</option>
								</Form.Select>
							</Form.Group>
							<Form.Group as={Col} sm={6} xs={12}>
								<Form.Label>Subject</Form.Label>
								<Form.Control onChange={e => setSubject(e.target.value)} type="text" />
							</Form.Group>
						</Row>
						<br />
						<Form.Group>
							<Form.Label>Description</Form.Label>
							<Form.Control as="textarea" onChange={(e) => setDescription(e.target.value)} rows={5} />
							<Form.Text className="text-muted">
								Detailed description of the idea, sales tip, general suggestion including any relevant details, benefits, and
								implementation suggestions
							</Form.Text>
						</Form.Group>
						<br />
						<Form.Group>
							<Form.Label>Attachments (optional)</Form.Label>
							<Form.Control
								multiple
								onChange={(e) => {
									const selectedFiles = (e.target as HTMLInputElement).files;
									setFiles(selectedFiles);
								}}
								type="file"
							/>
							<Form.Text className="text-muted">
								(5 files max, 20MB limit per file) Attach relevant files, such as documents, images, or presentations
							</Form.Text>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => hideModal()} variant="secondary">
						Close
					</Button>
					<Button onClick={saveIdea} variant="primary">
						Submit
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
