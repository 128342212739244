import React, {
	//Component,
	useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ClickAwayListener from "react-click-away-listener";

/**
 * ConfirmButton
 * @param disabled
 * @param action
 * @param Icon
 * @param ConfirmIcon
 * @param buttonStyles
 * @param disabledTooltipText
 * @param confirmText
 * @param tooltipText
 * @param className
 * @param size
 * @param initialVariant
 * @constructor
 */
export default function ConfirmButton({
	disabled,
	action,
	Icon,
	ConfirmIcon,
	buttonStyles,
    disabledTooltipText,
    confirmText,
    tooltipText,
    className,
    size,
	initialVariant = "primary"
}: {
	disabled?: boolean;
    disabledTooltipText?: string
	action: () => void;
	Icon: () => JSX.Element;
	ConfirmIcon: () => JSX.Element;
	buttonStyles?: React.CSSProperties;
    confirmText: string;
    tooltipText: string | null;
    className?: string;
	initialVariant?: "primary" | "secondary" | "warning" | "success" | "danger";
    size?: "sm" | "lg" | undefined;
}) {
	const [confirm, setConfirm] = useState(false);
	const [confirmed, setConfirmed] = useState(false);

	return (
        <ClickAwayListener onClickAway={() => setConfirm(false)}>
		<OverlayTrigger
            overlay={
                <Tooltip className="in" placement="top">
                    {disabled
                        ? disabledTooltipText
                        : confirm
                        ? confirmText
                        : tooltipText}
                </Tooltip>
            }
            placement="top"
        >
			<div className={className}>
				<Button
                    className={className}
					disabled={disabled}
					onClick={(e) => {
						e.stopPropagation();
						setConfirm(true);
						if (confirm) {
							setConfirm(false);
							setConfirmed(true);

							setTimeout(() => {
								setConfirmed(false);
							}, 1000);

							action();
						}
					}}
                    size={size ?? undefined}
					style={buttonStyles}
					variant={disabled ? "secondary" : confirm ? "warning" : confirmed ? "success" : initialVariant}>
					{confirm ? <ConfirmIcon /> : confirmed ? <i className="fa-solid fa-check"></i> : <Icon />}
				</Button>
			</div>
		</OverlayTrigger>
        </ClickAwayListener>
	);
}
