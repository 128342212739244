import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

/**
 * Enumerable FieldKeys
 */
export enum FieldKeys {
    FIRST_NAME = "1000",
    LAST_NAME = "1001",
    PRIMARY_PHONE = "1008",
	ALT_PHONE_2 = "1010",
    STREET = "1003",
    CITY = "1004",
    STATE = "1005",
    ZIP = "1006",
    EMAIL = "1007",
    HUBSPOT_ID = "300000000001069",
    LOAN_PURPOSE = "300000000001021",
    ESTIMATED_INTEREST = "300000000001065",
    EXISTING_MORTGAGE_BALANCE = "300000000001055",
    RECORDING_DATE = "300000000001076",
    CURRENT_LENDER = "300000000001075",
    LOAN_AMOUNT = "300000000001019",
    VENDOR = "300000000001050",
    CREDIT_SCORE = "300000000001017",
    TRIGGER_DATE = "300000000001081",
	DATE_OF_BIRTH = "300000000001090",
	GENERAL_CALLBACK_TIME = "300000000001091",
	LO_CALLBACK_TIME = "300000000001092",
	CRM_OWNER = "300000000001060",
	AGENT_USERNAME = "300000000001094",
	CALL_FORWARDING = "300000000001093",
	LOAN_TYPE = "300000000001101"
}

/**
 * Use Contact Fields
 */
export default function useContactFields() {
	const customer = useSelector((state: any) => state.customer);
	const lcCustomer = useSelector((state: any) => state.lcCustomer);
	const agentType = useSelector((state: any) => state.agentType);
    const [fieldsToInclude, setFieldsToInclude] = useState<any>();

	//Init
	useEffect(() => {
		if (customer && !lcCustomer) {
			const obj = {
				[FieldKeys.FIRST_NAME]: {
					label: "First Name",
					prop: "first_name",
					editable: true,
				},
				[FieldKeys.LAST_NAME]: {
					label: "Last Name",
					prop: "last_name",
					editable: true,
				},
				[FieldKeys.DATE_OF_BIRTH]: {
					label: "Date of Birth",
					prop: "Date_Of_Birth",
					editable: false,
				},
				[FieldKeys.PRIMARY_PHONE]: {
					label: "Primary Phone",
					prop: "number1",
					editable: false,
				},
				[FieldKeys.STREET]: {
					label: "Street",
					prop: "street",
					editable: true,
				},
				[FieldKeys.CITY]: {
					label: "City",
					prop: "city",
					editable: true,
				},
				[FieldKeys.STATE]: {
					label: "State",
					prop: "state",
					editable: true,
				},
				[FieldKeys.ZIP]: {
					label: "Zip",
					prop: "zip",
					editable: true,
				},
				[FieldKeys.EMAIL]: {
					label: "Email",
					prop: "email",
					editable: true,
				},
				[FieldKeys.CREDIT_SCORE]: {
					label: "Credit Score",
					prop: "Credit_Score",
					editable: true,
				},
				[FieldKeys.LOAN_PURPOSE]: {
					label: "Loan Purpose",
					prop: "Loan_Purpose",
					editable: true,
				},
				[FieldKeys.ESTIMATED_INTEREST]: {
					label: "Estimated Interest",
					prop: "EstimatedInterest",
					editable: false,
				},
				[FieldKeys.LOAN_TYPE]: {
					label: "Mortgage Type",
					prop: "Loan_Type",
					editable: false
				},
				[FieldKeys.EXISTING_MORTGAGE_BALANCE]: {
					label: "Existing Mortgage Balance",
					prop: "Existing_Mortgage_Balance",
					editable: false,
				},
				[FieldKeys.RECORDING_DATE]: {
					label: "Recording Date",
					prop: "Recording Date",
					editable: false,
				},
				[FieldKeys.VENDOR]: {
					label: "Vendor",
					prop: "Vendor",
					editable: false,
				},
				[FieldKeys.CURRENT_LENDER]: {
					label: "Current Lender",
					prop: "Current Lender",
					editable: false,
				},
				[FieldKeys.TRIGGER_DATE]: {
					label: "Event Date",
					prop: "Trigger Date",
					editable: false,
				},
			};

			setFieldsToInclude(obj);
		} else if (customer && lcCustomer) {
            const obj = {
				[FieldKeys.FIRST_NAME]: {
					label: "First Name",
					prop: "firstname",
					editable: false,
				},
				[FieldKeys.LAST_NAME]: {
					label: "Last Name",
					prop: "lastname",
					editable: false,
				},
				[FieldKeys.PRIMARY_PHONE]: {
					label: "Primary Phone",
					prop: "phone",
					editable: false,
				},
				[FieldKeys.STREET]: {
					label: "Street",
					prop: "street",
					editable: false,
				},
				[FieldKeys.CITY]: {
					label: "City",
					prop: "city",
					editable: false,
				},
				[FieldKeys.STATE]: {
					label: "State",
					prop: "state",
					editable: false,
				},
				[FieldKeys.ZIP]: {
					label: "Zip",
					prop: "zip",
					editable: false,
				},
				[FieldKeys.EMAIL]: {
					label: "Email",
					prop: "email",
					editable: false,
				},
				[FieldKeys.CREDIT_SCORE]: {
					label: "Credit Score",
					prop: "fico",
					editable: false,
				},
				[FieldKeys.LOAN_PURPOSE]: {
					label: "Loan Purpose",
					prop: "loan_purpose",
					editable: false,
				},
				[FieldKeys.ESTIMATED_INTEREST]: {
					label: "Estimated Interest",
					prop: "interest_rate_on_open_first_mortgage",
					editable: false,
				},
				[FieldKeys.EXISTING_MORTGAGE_BALANCE]: {
					label: "Existing Mortgage Balance",
					prop: "balance_on_open_first_mortgage_trades",
					editable: false,
				},
				[FieldKeys.DATE_OF_BIRTH]: {
					label: "Date of Birth",
					prop: "date_of_birth",
					editable: false,
				},
			};

			setFieldsToInclude(obj);
        } else {
			setFieldsToInclude(undefined);
		}
	}, [customer, lcCustomer]);

	//Return
    return fieldsToInclude;
}