import React from "react";
import { styled } from "styled-components";

/**
 * CommonQuestions
 * @constructor
 */
export default function CommonQuestions() {
	//Return
	return (
		<CommonQuestionsContainer>
			<h1>Common Questions for Filling Time</h1>
			<br />
			<div>
				<div className="questions-row">
					<div>
						<strong>Purchase:</strong>
						<ol>
							<li>How long have you been searching for a home?</li>
							<li>Are you working with a realtor?</li>
							<li>When would you like to close by?</li>
							<li>How many homes have you seen?</li>
							<li>Do you have a price range yet or looking to find out what that is?</li>
						</ol>
					</div>
					<div>
						<strong>Refinance:</strong>
						<ol>
							<li>How much cash are you wanting to get from the refinance?</li>
							<li>What are your goals with the cash out?</li>
							<li>How long have you lived in your home?</li>
							<li>How much will additional savings help you and your family?</li>
						</ol>
					</div>
				</div>
				<div className="questions-row">
					<div>
						<strong>We do not finance:</strong>
						<ul>
							<li>Commercial</li>
							<li>Land</li>
							<li>Business</li>
							<li>Hard Money</li>
							<li>Personal Loans</li>
						</ul>
					</div>
					<div>
						<strong>We will finance</strong>
						<ul>
							<li>Modular</li>
							<li>Double Wide</li>
							<li>Single Wide (Must be built in 1976 or later)</li>
							<li>Duplex (Also referred to as a 2 unit)</li>
						</ul>
					</div>
				</div>
			</div>
		</CommonQuestionsContainer>
	);
}

/**
 * StyledComponent
 */
const CommonQuestionsContainer = styled.div`
    & > div {
        display: flex;
        flex-direction: column;
    }

	.questions-row {
		display: flex;
		column-gap: 10px;
	}

	.questions-row > * {
		flex: 1;
	}
`;
