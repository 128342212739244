import moment from "moment";
import React, { useState } from "react";
import { Button, ButtonGroup, FormControl, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setHotlist } from "../../redux/globalSlice";
import { postMessage } from "../../utils";
import ConfirmButton from "../ConfirmButton";

/**
 * ScheduledCallbackModal
 * @param hubspotObj
 * @constructor
 */
export default function ScheduledCallbackModal({ hubspotObj }: { hubspotObj: any }) {
	const [showModal, setShowModal] = useState(false);
	const [date, setDate] = useState<string>("");
	const hotlist = useSelector((state: any) => state.hotlist);
	const dispatch = useDispatch();

	/**
	 * Hide Modal -- func
	 */
	const hideModal = () => {
		setShowModal(false);
		setDate("");
	};

	/**
	 * Confirm Callback -- func
	 */
	const confirmCallback = () => {
		const formattedDate = date === "" ? date : moment(date).utc().toString();
		postMessage({ action: "SetScheduledCallback", value: { hubspotId: hubspotObj.id, date: formattedDate } });
		const newHotlist = hotlist.map((item: any) => {
			if (item.id === hubspotObj.id) {
				return { ...item, properties: { ...item.properties, callback_appointment_ts: formattedDate } };
			}

			return item;
		});
		dispatch(setHotlist(newHotlist));
		setDate("");
		setShowModal(false);
	};

	//Return
	return hubspotObj ? (
		<>
			<ButtonGroup>
				<ConfirmButton
					ConfirmIcon={() => <i className="fa-solid fa-ban"></i>}
					Icon={() => <i className="fa-solid fa-xmark"></i>}
					action={() => {
						postMessage({ action: "SetScheduledCallback", value: { hubspotId: hubspotObj.id, date: "" } });
						
						const newHotlist = hotlist.map((item: any) => {
							if (item.id === hubspotObj.id) {
								return { ...item, properties: { ...item.properties, callback_appointment_ts: "" } };
							}

							return item;
						});

						dispatch(setHotlist(newHotlist));
					}}
					buttonStyles={{ borderRadius: 0}}
					confirmText="Are you sure you want to clear the scheduled callback?"
					size="sm"
					tooltipText="Clear Scheduled Callback"
				/>
				<Button onClick={() => setShowModal(true)} size="sm" style={{ borderRadius: 0 }}>
					<i className="fa-regular fa-calendar"></i>
				</Button>
			</ButtonGroup>
			<Modal centered onHide={hideModal} show={showModal}>
				<Modal.Header>
					<Modal.Title>
						Call {hubspotObj.properties?.firstname} {hubspotObj.properties?.lastname} back
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Please set a date and a time for the callback to occur</p>
					<FormControl onChange={(e) => setDate(e.target.value)} type="datetime-local" />
					<p>
						Timezone: <strong>{new Date().toTimeString().slice(9)}</strong>
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={hideModal} variant="secondary">
						Close
					</Button>
					<Button onClick={confirmCallback} variant="primary">
						Confirm
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	) : (
		<></>
	);
}
