import { EventEmitter } from "events";

/**
 * Init. new EventEmitter
 */
const eventEmitter = new EventEmitter();
export { eventEmitter };

/**
 * Reserved Lead To Boolean
 * @param prop
 */
export const reservedLeadToBoolean = (prop: any) => {
	return prop === "true" || prop === true;
};

/**
 * Get Script Variable Value
 * @param property
 * @param variables
 */
export const getScriptVariableValue = (property: string, variables: any) => {
	const obj: any = {
		Agent: variables.agent,
		Customer: variables.customer,
		Call: variables.call,
	};

	const prop = property.slice(1, -1).split(".");
	return obj?.[prop[0]]?.[prop[1]] ?? "(Unknown)";
};

/**
 * Format Phone Number
 * @param value
 */
export const formatPhoneNumber = (value: string) => {
	var cleaned = ("" + value).replace(/\D/g, "");
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? "+1 " : "";
		return ["(", match[2], ") ", match[3], "-", match[4]].join("");
	}
	return null;
};

/**
 * Get name - func
 * Gets full name from item object
 * @param item
 */
export function getName(item: any) {
	return item.properties?.firstname && item.properties?.lastname
		? `${item.properties.firstname} ${item.properties.lastname}`
		: item.properties?.firstname || item.properties?.lastname || "No Name";
}

/**
 * Sort Hotlist By Date Furthest Out - func
 * @param hotlist
 */
export function sortHotlistByDateFurthestOut(hotlist: any[]) {
	const sorted = [...hotlist];

	sorted.sort((a: any, b: any) => {
		let aValue: any = getLastContactedDate(a);
		let bValue: any = getLastContactedDate(b);

		if (aValue) aValue = new Date(aValue);
		if (bValue) bValue = new Date(bValue);

		if (!aValue) return 1;
		if (!bValue) return -1;
		if (aValue === bValue) return 0;

		return aValue > bValue ? 1 : -1;
	});

	return sorted;
}

/**
 * Get Last Contacted Date -- func
 * @param item
 */
export function getLastContactedDate(item: any) {
	const date = item.properties?.five9_last_dial_start_timestamp;
	const five9LastDial =
		date?.length === 22
			? new Date([date.slice(0, 10), " ", date.slice(10)].join("")).toISOString().replace("Z", "")
			: date;

	return five9LastDial && item.properties?.botsplash_last_sms
		? new Date(`${five9LastDial}Z`) > new Date(item.properties.botsplash_last_sms)
			? new Date(`${five9LastDial}Z`).toLocaleString()
			: new Date(item.properties.botsplash_last_sms).toLocaleString()
		: five9LastDial
		? new Date(`${five9LastDial}Z`).toLocaleString()
		: item.properties.botsplash_last_sms
		? new Date(item.properties.botsplash_last_sms).toLocaleString()
		: undefined;
}

/**
 * Is Callable Lead -- func
 * Checks if lead is callable
 * @param item
 */
export function isCallableLead(item: any) {
	const lastContactedDate = getLastContactedDate(item);

	const oneHourLess = new Date();
	oneHourLess.setHours(oneHourLess.getHours() - 1);

	const phone = getValidHubspotPhone(item);

	return (
		phone &&
		(!lastContactedDate || new Date(lastContactedDate) < oneHourLess) &&
		!reservedLeadToBoolean(item.properties?.reserved_lead) &&
		(!item.properties?.callback_appointment_ts || new Date(item.properties.callback_appointment_ts) < new Date()) &&
		(!item.properties?.hubspot_owner_assigneddate ||
			new Date(item.properties.hubspot_owner_assigneddate).toDateString() !== new Date().toDateString())
	);
}

/**
 * Notify -- func
 * Fires new notification
 * @param message
 */
export function notify(message: string) {
	if (!("Notification" in window)) {
		alert("This browser does not support desktop notification");
	} else if (Notification.permission === "granted") {
		const notification = new Notification("HEY!", { body: message, requireInteraction: true });
		setTimeout(() => notification.close(), 60000);
	} else if (Notification.permission !== "denied") {
		Notification.requestPermission().then((permission) => {
			if (permission === "granted") {
				const timeout = new Notification("HEY!", { body: message, requireInteraction: true });
				setTimeout(() => timeout.close(), 60000);
			}
		});
	}
}

/**
 * Ready State Payload -- func
 * Returns ready state payload object TODO: T: add mor detail
 */
export function readyStatePayload() {
	return { notReadyReasonCodeId: 0, readyChannels: ["CALL", "VOICE_MAIL"] };
}

/**
 * Not Ready State Payload -- func
 * Returns object TODO: t: be more clear of func
 */
export function notReadyStatePayload() {
	return { notReadyReasonCodeId: -1, readyChannels: [] };
}

/**
 * Format Phone To Digits Only -- func
 * Returns reformated string in valid phone number format
 * @param value
 */
export function formatPhoneToDigitsOnly(value: string | undefined) {
	//If value empty return empty string
	if (!value) return "";
	//Reformat string
	return value.replace(/\D+/g, "").replace(/^\+?1|\|1|\D/g, "");
}

/**
 * Get Valid Hubspot Phone -- func
 * Returns first found number five9_last_dialed_number, phone, phone_number_2, phone_number_3
 * @param item
 */
export function getValidHubspotPhone(item: any): string {
	return (
		formatPhoneToDigitsOnly(item.properties?.five9_last_dialed_number) ||
		formatPhoneToDigitsOnly(item.properties?.phone) ||
		formatPhoneToDigitsOnly(item.properties?.phone_number_2) ||
		formatPhoneToDigitsOnly(item.properties?.phone_number_3) ||
		""
	);
}

/**
 * Post Message -- func
 * Posts message using new CustomEvent
 * @param data
 */
export function postMessage(data: any) {
	//Create new CustomEvent
	const event = new CustomEvent("adtPostMessage", {
		detail: data,
	});
	//Fire event
	document.dispatchEvent(event);
}

/**
 * Should Render -- func
 * Check if you should render or not
 * @param renderIf
 * @param variables
 */
export function shouldRender(renderIf: any, variables: any) {
	return (
		renderIf?.every((condition: any) => {
			const varValue = getScriptVariableValue(condition.variable, variables);

			switch (condition.operator) {
				case "GREATER_THAN_OR_EQUAL_TO":
					return varValue >= condition.value;
				case "LESS_THAN_OR_EQUAL_TO":
					return varValue <= condition.value;
				case "LESS_THAN":
					return varValue < condition.value;
				case "GREATER_THAN":
					return varValue > condition.value;
				case "EQUALS":
					return varValue == condition.value;
				case "NOT_EQUALS":
					return varValue != condition.value;
				case "INCLUDES":
					return varValue?.includes(condition.value);
				case "EXCLUDES":
					return !varValue?.includes(condition.value);
				default:
					return;
			}
		}) ?? true
	);
}

/**
 * Get U.S. states aberrations -- func
 * Returns array of state aberrations
 */
export function getAbbrStates() {
	return [
		"",
		"AL",
		"AZ",
		"CA",
		"CO",
		"FL",
		"ID",
		"IL",
		"IA",
		"ME",
		"MI",
		"MN",
		"MT",
		"NH",
		"NJ",
		"NC",
		"OH",
		"OK",
		"OR",
		"PA",
		"SC",
		"SD",
		"TN",
		"TX",
		"WA",
		"WI",
		"WY",
	];
}