import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { postMessage } from "../utils";
import CallSurvey from "./CallSurvey";

/**
 * Conference
 * @constructor
 */
export default function Conference() {
	const call = useSelector((state: any) => state.call);
	const customer = useSelector((state: any) => state.customer);

	const lo = call?.LoName;

	return lo && lo !== "(Unknown LO Name)" ? (
		<ConferenceContainer>
			<div>Loan Officer</div>
			<strong>{lo}</strong>
			<Button
				onClick={() => {
					toast.info(
						<CallSurvey callFinishedAdtData={{ number: `agent:${lo}`, callId: call.call_id }} callFinishedCustomer={{ first_name: customer.first_name, last_name: customer.last_name }} />,
						{ closeOnClick: false, autoClose: 10000 }
					);
					postMessage({ action: "LeaveConference" });
				}}
				size="lg"
				style={{ width: "100%" }}
				variant="success">
				Complete Transfer
			</Button>
		</ConferenceContainer>
	) : (
		<></>
	);
}

/**
 * StyledComponent
 */
const ConferenceContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 10px;
	padding: 10px;
`;
