import axios from "axios";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip, Button, Modal, Container, Row, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { formatPhoneNumber, getAbbrStates,
	//getName,
	getValidHubspotPhone, postMessage } from "../../utils";
import { FieldKeys } from "../hooks/useContactFields";

/**
 * Today/now
 */
const today = new Date();

/**
 * Initial Payload
 * @param item
 */
const initialPayload = (item: any) => ({
	LeadID: item.id,
	Created: today.toISOString().split("T")[0],
	FirstName: item.properties?.firstname,
	MiddleName: "",
	LastName: item.properties?.lastname,
	Email: item.properties?.email,
	FICO: item.properties?.fico,
	Phone: getValidHubspotPhone(item),
	PropertyAddress: item.properties?.address,
	PropertyCity: item.properties?.city,
	PropertyState: item.properties?.state,
	PropertyZip: item.properties?.zip,
	LoanOfficer: "",
	LoanPurpose: item.properties?.loan_purpose,
	Address: item.properties?.address,
	City: item.properties?.city,
	State: item.properties?.state,
	Zip: item.properties?.zip,
});

/**
 * Field Empty -- func
 * @param field
 */
const fieldEmpty = (field: string) => {
	return !field.trim() || field == null;
};

/**
 * HubSpotDetailsModal
 * @param item
 * @constructor
 */
export default function HubSpotDetailsModal({ item }: { item: any }) {
	const [showModal, setShowModal] = useState(false);
	const [payload, setPayload] = useState<any>();
	const agent = useSelector((state: any) => state.agent);
	const [validated, setValidated] = useState(false);

	/**
	 * Hide Modal -- func
	 */
	const hideModal = () => {
		setShowModal(false);
	};

	/**
	 * Set Value -- func
	 * @param e
	 */
	const setValue = (e: any) => {
		setPayload((prev: any) => {
			const newPayload = { ...prev, [e.target.name]: e.target.value };

			if (["Address", "City", "State", "Zip"].includes(e.target.name)) {
				newPayload.PropertyAddress = newPayload.Address;
				newPayload.PropertyCity = newPayload.City;
				newPayload.PropertyState = newPayload.State;
				newPayload.PropertyZip = newPayload.Zip;
			}

			return newPayload;
		});

		let fields: any = {
			[FieldKeys.HUBSPOT_ID]: item.id,
		};

		switch (e.target.name) {
			case "FirstName":
				fields = { ...fields, [FieldKeys.FIRST_NAME]: e.target.value };
				break;
			case "LastName":
				fields = { ...fields, [FieldKeys.LAST_NAME]: e.target.value };
				break;
			case "Address":
				fields = { ...fields, [FieldKeys.STREET]: e.target.value };
				break;
			case "City":
				fields = { ...fields, [FieldKeys.CITY]: e.target.value };
				break;
			case "State":
				fields = { ...fields, [FieldKeys.STATE]: e.target.value };
				break;
			case "Zip":
				fields = { ...fields, [FieldKeys.ZIP]: e.target.value };
				break;
			case "Email":
				fields = { ...fields, [FieldKeys.EMAIL]: e.target.value };
				break;
			case "LoanPurpose":
				fields = { ...fields, [FieldKeys.LOAN_PURPOSE]: e.target.value };
				break;
			case "FICO":
				fields = { ...fields, [FieldKeys.CREDIT_SCORE]: e.target.value };
				break;
			default:
				break;
		}

		const contactObj = {
			fields,
		};

		postMessage({ action: "UpdateHubSpotContact", value: contactObj });
	};

	/**
	 * Submit Loan App -- func
	 * @param e
	 */
	const submitLoanApp = async (e: any) => {
		e.preventDefault();

		if (e.currentTarget.checkValidity() === false) {
		} else {
			let validationFailed = false;

			Object.entries(payload).forEach(([key, value]) => {
				const trimmedValue = value?.toString() ?? "";

				if (["LoanOfficer", "LoanPurpose", "FirstName", "LastName", "Email"].includes(key) && fieldEmpty(trimmedValue)) {
					toast.error(`Please fill out the ${key} field`);
					validationFailed = true;
				}
			});

			if (
				!confirm(
					"Are you sure you would like to send this loan application? Please ensure that all fields are accurate."
				) ||
				validationFailed
			) {
				return;
			}

			try {
				const result = await axios.post("https://hooks.zapier.com/hooks/catch/11427652/3vs6j6z", JSON.stringify(payload));
				toast.success("Successfully submitted loan application");
			} catch (e) {
				toast.error("Failed to submit loan application");
			}
		}

		setValidated(true);
	};

	/**
	 * On Key Down -- func
	 * @param e
	 */
	const onKeyDown = (e: React.KeyboardEvent<any>) => {
		if (e.key === "Enter") {
			e.currentTarget.blur();
		}
	};

	/**
	 * Disable Select Key Events -- func
	 * @param e
	 */
	const disableSelectKeyEvents = (e: React.KeyboardEvent<any>) => {
		if (e.key !== "Tab") {
			e.preventDefault();
		}
	};

	//Init...
	useEffect(() => {
		if (item) {
			setPayload({ ...initialPayload(item), LoanOfficer: agent?.email });
		}
	}, [agent?.email, item]);

	//Return
	return (
		<>
			<OverlayTrigger
				overlay={
					<Tooltip className="in" placement="top">
						View Client Data
					</Tooltip>
				}
				placement="top">
				<Button
					onClick={(e) => {
						e.stopPropagation();
						setShowModal(true);
					}}>
					<i className="fa-solid fa-magnifying-glass"></i>
				</Button>
			</OverlayTrigger>
			<Modal centered onHide={() => hideModal()} show={showModal}>
				<Form noValidate onSubmit={submitLoanApp} validated={validated}>
					<Modal.Header closeButton>
						<Modal.Title>Client Details</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<DataContainer>
							<Row>
								<Col xs={6}>
									First Name <Required />
								</Col>
								<Col xs={6}>
									<Form.Control
										defaultValue={payload?.FirstName}
										name="FirstName"
										onBlur={setValue}
										onKeyDown={onKeyDown}
										required
										style={{ fontWeight: "bold" }}
									/>
									<Form.Control.Feedback type="invalid">Please provide a first name</Form.Control.Feedback>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>
									Last Name <Required />
								</Col>
								<Col xs={6}>
									<Form.Control
										defaultValue={payload?.LastName}
										name="LastName"
										onBlur={setValue}
										onKeyDown={onKeyDown}
										required
										style={{ fontWeight: "bold" }}
									/>
									<Form.Control.Feedback type="invalid">Please provide a last name</Form.Control.Feedback>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>Address</Col>
								<Col xs={6}>
									<Form.Control
										defaultValue={payload?.Address}
										name="Address"
										onBlur={setValue}
										onKeyDown={onKeyDown}
										style={{ fontWeight: "bold" }}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>City</Col>
								<Col xs={6}>
									<Form.Control defaultValue={payload?.City} name="City" onBlur={setValue} style={{ fontWeight: "bold" }} />
								</Col>
							</Row>
							<Row>
								<Col xs={6}>State</Col>
								<Col xs={6}>
									<Form.Select
										name="State"
										onChange={setValue}
										onKeyDown={disableSelectKeyEvents}
										style={{ fontWeight: "bold" }}
										value={payload?.State}>
										{getAbbrStates().map((state) => (
											<option key={state} value={state}>
												{state}
											</option>
										))}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>Zip</Col>
								<Col xs={6}>
									<Form.Control defaultValue={payload?.Zip} name="Zip" onBlur={setValue} style={{ fontWeight: "bold" }} />
								</Col>
							</Row>
							<Row>
								<Col xs={6}>Last Dialed Number</Col>
								<Col xs={6}>{formatPhoneNumber(item.properties?.five9_last_dialed_number)}</Col>
							</Row>
							<Row>
								<Col xs={6}>Phone</Col>
								<Col xs={6}>{formatPhoneNumber(item.properties?.phone)}</Col>
							</Row>
							<Row>
								<Col xs={6}>Phone 2</Col>
								<Col xs={6}>{formatPhoneNumber(item.properties?.phone_number_2)}</Col>
							</Row>
							<Row>
								<Col xs={6}>Phone 3</Col>
								<Col xs={6}>{formatPhoneNumber(item.properties?.phone_number_3)}</Col>
							</Row>
							<Row>
								<Col xs={6}>FICO</Col>
								<Col xs={6}>
									<Form.Control
										defaultValue={payload?.FICO}
										name="FICO"
										onBlur={setValue}
										onKeyDown={onKeyDown}
										style={{ fontWeight: "bold" }}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>
									Email <Required />
								</Col>
								<Col xs={6}>
									<Form.Control
										defaultValue={payload?.Email}
										name="Email"
										onBlur={setValue}
										onKeyDown={onKeyDown}
										required
										style={{ fontWeight: "bold" }}
									/>
									<Form.Control.Feedback type="invalid">Please provide a valid email</Form.Control.Feedback>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>
									Loan Purpose <Required />
								</Col>
								<Col xs={6}>
									<Form.Select
										name="LoanPurpose"
										onChange={setValue}
										onKeyDown={disableSelectKeyEvents}
										required
										style={{ fontWeight: "bold" }}
										value={["Refinance", "Purchase"].includes(payload?.LoanPurpose) ? payload?.LoanPurpose : ""}>
										<option value="">Select Loan Purpose</option>
										<option value="Refinance">Refinance</option>
										<option value="Purchase">Purchase</option>
									</Form.Select>
									<Form.Control.Feedback type="invalid">Please provide a loan purpose</Form.Control.Feedback>
								</Col>
							</Row>
						</DataContainer>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => hideModal()} variant="secondary">
							Close
						</Button>
						<Button type="submit" variant="success">
							Create Loan Application
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
}

/**
 * StyledComponent
 */
const DataContainer = styled(Container)`
	.row {
		align-items: center;
		margin-bottom: 5px;
	}

	.row > :last-child {
		text-align: right;
		font-weight: bold;
	}
`;

/**
 * StyledComponent
 */
const Required = styled.span`
	color: red;

	&::after {
		content: "*";
	}
`;
