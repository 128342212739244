import _ from "lodash";
import React, { useRef, useState } from "react";
import { Button, ButtonGroup, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import ClickAwayListener from "react-click-away-listener";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import SunEditor from "suneditor-react";
import {
	getValidHubspotPhone,
	formatPhoneToDigitsOnly,
	getLastContactedDate,
	reservedLeadToBoolean,
	getName,
	formatPhoneNumber,
	postMessage,
} from "../../../utils";
import ConfirmButton from "../../ConfirmButton";
import ScheduledCallbackModal from "../../modals/ScheduledCallbackModal";
import HubSpotDetailsModal from "../../modals/HubSpotDetailsModal";
import ReferModal from "../../modals/ReferModal";

/**
 * Button list
 */
const buttonList = [
	["bold", "italic", "underline", "strike"],
	["list", "align", "fontColor", "hiliteColor"],
];

/**
 * RowWithDropdown
 * @param item
 * @param updateLocalNote
 * @param setReservedLead
 * @constructor
 */
export default function RowWithDropdown({
	item,
	updateLocalNote,
	setReservedLead,
}: {
	item: any;
	updateLocalNote: (note: string, id: string) => void;
	setReservedLead: (id: string, reserved: boolean) => void;
}) {
	const readyState = useSelector((state: any) => state.readyState);
	const [showDropdown, setShowDropdown] = useState(false);
	const isReady = readyState?.currentState?.readyChannels?.length > 0;
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [confirmRemove, setConfirmRemove] = useState(false);
	const [confirmedRemove, setConfirmedRemove] = useState(false);
	const customer = useSelector((state: any) => state.customer);
	const theme = useSelector((state: any) => state.theme);
	const agent = useSelector((state: any) => state.agent);

	async function updateNote(text: string) {
		postMessage({ action: "UpdateNote", value: { note: text, hubspotId: item.id } });
	}

	const debouncedSearch = useRef(
		_.debounce(async (text) => {
			setUnsavedChanges(false);
			updateNote(text);
		}, 2000)
	).current;

	const noteChanged = (newValue: string) => {
		debouncedSearch.cancel();
		setUnsavedChanges(true);
		debouncedSearch(newValue);
	};

	const callback = item.properties?.callback_appointment_ts
		? new Date(item.properties.callback_appointment_ts).toLocaleString()
		: "";

	const phone = getValidHubspotPhone(item);

	const hotlistBackgroundColor =
		phone != null &&
		phone !== "" &&
		customer?.calledFromNumber != null &&
		formatPhoneToDigitsOnly(phone) === formatPhoneToDigitsOnly(customer?.calledFromNumber)
			? theme === "dark"
				? "darkgreen"
				: "lightgreen"
			: undefined;

	const lastContactedDate = getLastContactedDate(item);

	const isDialedOrMessagedWithin48Hours = lastContactedDate
		? new Date(lastContactedDate) >= new Date(Date.now() - 48 * 60 * 60 * 1000)
		: false;

	return (
		<ClickAwayListener onClickAway={() => setShowDropdown(false)}>
			<HotlistTableRow $backgroundColor={hotlistBackgroundColor} style={{ position: "relative" }}>
				<td>
					<div style={{ display: "flex", alignItems: "center" }}>
						<ConfirmButton
							ConfirmIcon={() =>
								reservedLeadToBoolean(item.properties?.reserved_lead) || item.properties?.source === "Self-Sourced" ? (
									<i className="fa-solid fa-xmark"></i>
								) : (
									<i className="fa-solid fa-check"></i>
								)
							}
							Icon={() =>
								reservedLeadToBoolean(item.properties?.reserved_lead) || item.properties?.source === "Self-Sourced" ? (
									<i className="fa-solid fa-shield"></i>
								) : isDialedOrMessagedWithin48Hours ? (
									<i className="fa-solid fa-lock"></i>
								) : (
									<i className="fa-solid fa-lock-open"></i>
								)
							}
							action={() => {
								setReservedLead(item.id, !reservedLeadToBoolean(item.properties?.reserved_lead));
								postMessage({
									action: reservedLeadToBoolean(item.properties?.reserved_lead) ? "RemoveReservedLead" : "AddReservedLead",
									value: {
										HubSpotID: item.id,
										Agent: agent?.email,
									},
								});
							}}
							buttonStyles={{ borderRadius: 0 }}
							confirmText={`Click again to ${
								reservedLeadToBoolean(item.properties?.reserved_lead) ? "unshield" : "shield"
							} lead`}
							initialVariant={
								reservedLeadToBoolean(item.properties?.reserved_lead) || item.properties?.source === "Self-Sourced"
									? item.properties?.source === "Self-Sourced"
										? "primary"
										: "success"
									: isDialedOrMessagedWithin48Hours
									? "primary"
									: "warning"
							}
							size="sm"
							tooltipText={
								reservedLeadToBoolean(item.properties?.reserved_lead) || item.properties?.source === "Self-Sourced"
									? item.properties?.source === "Self-Sourced"
										? "Protected: This lead is protected because it is self-sourced."
										: "Protected: This lead is protected and cannot be reassigned. Click to unprotect."
									: isDialedOrMessagedWithin48Hours
									? "Lead is Protected: Contact made within the last 48 hours. Click to protect."
									: "Lead is Unprotected: Contact was NOT made within the last 48 hours. Click to protect."
							}
						/>
						<Button
							onClick={() => {
								window.open(`https://app.hubspot.com/contacts/23432035/contact/${item.id}`, "_blank");
							}}
							variant="link">
							{getName(item)}
						</Button>
					</div>
				</td>
				<td>
					{item.properties?.hubspot_owner_assigneddate ? new Date(item.properties?.hubspot_owner_assigneddate).toLocaleString() : ""}
				</td>
				<td>{lastContactedDate}</td>
				<td>
					<div style={{ display: "flex", alignItems: "center" }}>
						<ScheduledCallbackModal hubspotObj={item} />
						&nbsp;
						{callback ? (
							<span style={{ color: new Date(callback) < new Date() ? "#ff6f61" : undefined }}>{callback}</span>
						) : (
							callback
						)}
					</div>
				</td>
				<td style={{ textAlign: "right" }}>
					<ButtonGroup>
						<OverlayTrigger
							overlay={
								item.properties?.notes ? (
									<Tooltip className="in" placement="top">
										<div dangerouslySetInnerHTML={{ __html: item.properties?.notes }} />
									</Tooltip>
								) : (
									<></>
								)
							}
							placement="top">
							<Button
								onClick={(e) => {
									e.stopPropagation();
									setShowDropdown((prev) => !prev);
								}}>
								{item.properties?.notes ? <i className="fa-regular fa-file-lines"></i> : <i className="fa-solid fa-file"></i>}
							</Button>
						</OverlayTrigger>
						<HubSpotDetailsModal item={item} />
						<ReferModal item={item} />
						<OverlayTrigger
							overlay={
								<Tooltip className="in" placement="top">
									Click to expand BotSplash
								</Tooltip>
							}
							placement="top">
							<Button
								onClick={() =>
									postMessage({
										action: "OpenBotSplash",
										value: item.id,
									})
								}>
								<i className="fa-solid fa-comment-sms"></i>
							</Button>
						</OverlayTrigger>
						<ConfirmButton
							ConfirmIcon={() => <i className="fa-solid fa-phone-volume"></i>}
							Icon={() => <i className="fa-solid fa-phone"></i>}
							action={() => {
								postMessage({
									action: "DialNumber",
									value: {
										number: formatPhoneToDigitsOnly(phone),
										campaignId: "300000000000736",
										five9_id: item.properties?.five9_id
									},
								});
							}}
							buttonStyles={{ borderRadius: 0 }}
							confirmText="Click again to confirm call"
							disabled={isReady || !phone}
							disabledTooltipText="Button disabled while in Ready mode. Switch to Not Ready mode to enable this button and make an outbound call."
							tooltipText={formatPhoneNumber(phone)}
						/>
						<ClickAwayListener onClickAway={() => setConfirmRemove(false)}>
							<OverlayTrigger
								overlay={
									<Tooltip className="in" placement="top">
										{confirmRemove ? "Click again to unassign lead" : "Unassign"}
									</Tooltip>
								}
								placement="top">
								<Button
									onClick={(e) => {
										e.stopPropagation();
										setConfirmRemove(true);

										if (confirmRemove) {
											setConfirmRemove(false);
											setConfirmedRemove(true);

											setTimeout(() => {
												setConfirmedRemove(false);
											}, 3000);

											postMessage({ action: "RemoveFromHotlist", value: item.id });
										}
									}}
									variant={confirmRemove ? "danger" : confirmedRemove ? "success" : "secondary"}>
									{confirmRemove ? (
										<i className="fa-solid fa-trash"></i>
									) : confirmedRemove ? (
										<i className="fa-solid fa-check"></i>
									) : (
										<i className="fa-solid fa-xmark"></i>
									)}
								</Button>
							</OverlayTrigger>
						</ClickAwayListener>
					</ButtonGroup>
				</td>
				{showDropdown && (
					<DropdownContent>
						<div>
							<SunEditor
								height="300px"
								onBlur={(_, content) => {
									updateLocalNote(content, item.id);
								}}
								onChange={noteChanged}
								setContents={item.properties?.notes}
								setDefaultStyle="font-family: arial; font-size: 14px"
								setOptions={{
									buttonList,
								}}
							/>
							<div style={{ textAlign: "center" }}>
								<Form.Text>{unsavedChanges ? "Unsaved Changes" : "Changes Saved!"}</Form.Text>
							</div>
						</div>
					</DropdownContent>
				)}
			</HotlistTableRow>
		</ClickAwayListener>
	);
}

/**
 * StyledComponent
 */
const HotlistTableRow = styled.tr<{ $backgroundColor?: string }>`
	${(props) => props.$backgroundColor && `td { background-color: ${props.$backgroundColor}; }`}
`;

/**
 * StyledComponent
 */
const DropdownContent = styled.div({
	position: "absolute",
	left: 0,
	top: 65,
	width: "100%",
	padding: 10,
	zIndex: 1,
	border: "1px solid grey",
});