import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import { formatPhoneToDigitsOnly, postMessage } from "../utils";

/**
 * Dialer
 * @constructor
 */
export default function Dialer() {
	const customer = useSelector((state: any) => state.customer);
	const readyState = useSelector((state: any) => state.readyState);
	const [number, setNumber] = useState<any>();
	const isDisabled = !!customer || readyState?.currentState?.readyChannels?.length > 0;

	//Init...
	useEffect(() => {
		if (customer) {
			setNumber("");
		}
	}, [customer]);

	//Return
	return (
		<div style={{ marginTop: 15, textAlign: "center" }}>
			<DialerContainer>
				<Form.Control
					disabled={isDisabled}
					onChange={(e) => setNumber(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							postMessage({ action: "DialNumber", value: formatPhoneToDigitsOnly(number) });
						}
					}}
					placeholder="Type number..."
					type="text"
					value={number}
				/>
				<Button
					disabled={isDisabled}
					onClick={() => {
						postMessage({ action: "DialNumber", value: formatPhoneToDigitsOnly(number) });
					}}
					variant={isDisabled ? "secondary" : "primary"}>
					Dial
				</Button>
			</DialerContainer>
		</div>
	);
}

/**
 * StyledComponent
 */
const DialerContainer = styled.div`
	display: flex;
	padding: 10px;

	& > * {
		border-radius: 0;
	}
`;
