import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { getScriptVariableValue, shouldRender } from "../utils";

/**
 * Scripts
 * @constructor
 */
export default function Scripts() {
	const environment = useSelector((state: any) => state.environment);
	const agent = useSelector((state: any) => state.agent);
	const call = useSelector((state: any) => state.call);
	const customer = useSelector((state: any) => state.customer);
	const isTransferred = useSelector((state: any) => state.isTransferred);
	const [scripts, setScripts] = useState<any>();
	const [scriptsToRender, setScriptsToRender] = useState<any[]>([]);
	const [script, setScript] = useState<string>();
	const agentType = useSelector((state: any) => state.agentType);

	/**
	 * Set Script Text -- func
	 */
	const setScriptText = useCallback(
		(title: string, scriptsToRenderArr: any[]) => {
			if (!scriptsToRenderArr.length) {
				setScript(undefined);
				return;
			}
			
			const scriptObj = scriptsToRenderArr.find((s) => s.title === title);
			const regex = /@(.*?)@/g;
			const matches = scriptObj.script.match(regex);
			let scriptText = scriptObj.script;

			matches.forEach((match: any) => {
				let replacement = getScriptVariableValue(match, { agent, call, customer });

				if (!isNaN(replacement) && !match.includes("zip")) {
					replacement = Number(replacement).toLocaleString();
				}

				scriptText = scriptText.replaceAll(match, replacement);
			});

			if (title === "Transfer") {
				scriptText = scriptText.replaceAll("(LO Name)", call?.LoName ?? "(Unknown LO Name)");
			}

			setScript(scriptText);
		},
		[agent, call, customer]
	);

	//Init...
	useEffect(() => {
		if (isTransferred) {
			setScriptText("Transfer", scriptsToRender);
		}
	}, [isTransferred, scriptsToRender, setScriptText]);

	useEffect(() => {
		async function getScripts() {
			try {
				const url = environment.isLocal
					? agentType === "CCS"
						? "scripts/scripts.json"
						: "scripts/lo-scripts.json"
					: agentType === "CCS"
					? `${environment.azureUrl}/scripts.json`
					: `${environment.azureUrl}/lo-scripts.json`;
					//: `${environment.azureUrl}/lo-scripts-test.json`;

				const result = await axios.get(url);
				setScripts(result.data);
			} catch (e) {
				toast.error("Failed to retrieve scripts");
			}
		}

		if (agentType) {
			getScripts();
		}
	}, [environment, agentType]);

	useEffect(() => {
		if (scripts?.length > 0 && call && customer && agent) {
			const scriptsToRenderArr: any = [];

			scripts.forEach((script: any) => {
				if (shouldRender(script.renderIf, { agent, call, customer })) {
					scriptsToRenderArr.push(script);
				}
			});

			setScriptsToRender(scriptsToRenderArr);
			setScriptText(scriptsToRenderArr?.[0]?.title, scriptsToRenderArr);
		}
	}, [agent, call, customer, scripts, setScriptText]);

	//Return
	return script && customer ? (
		<div style={{ width: 400 }}>
			<h1 style={{ textAlign: "center" }}>Script</h1>
			{script && <p dangerouslySetInnerHTML={{ __html: script }} />}
			<ScriptButtonsContainer>
				{scriptsToRender.map((script: any) => (
					<Button
						key={script.title}
						onClick={() => setScriptText(script.title, scriptsToRender)}
						value={script.title}
						variant="secondary">
						{script.title}
					</Button>
				))}
			</ScriptButtonsContainer>
		</div>
	) : (
		<></>
	);
}

/**
 * StyledComponent
 */
const ScriptButtonsContainer = styled.div({
	display: "flex",
	flexWrap: "wrap",
	rowGap: 10,
	justifyContent: "center",
	columnGap: 10,
});