import React, { useCallback, useEffect, useState, forwardRef, useRef } from "react";
//import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { formatPhoneNumber, postMessage } from "../utils";
import IdeasModal from "./modals/IdeasModal";
import Productivity from "./Productivity";
import Settings from "./Settings";
import { FieldKeys } from "./hooks/useContactFields";
import pjmc from "../assets/pjmc.png";

/**
 * Header
 * @param setAdtBarHeight
 * @constructor
 */
function Header({ setAdtBarHeight }: { setAdtBarHeight: React.Dispatch<React.SetStateAction<number>> }) {
	const callStartedAdtData = useSelector((state: any) => state.callStartedAdtData);
	const customer = useSelector((state: any) => state.customer);
	const callStatus = useSelector((state: any) => state.callStatus);
	const [callColor, setCallColor] = useState<string>();
	const [friendlyCampaignName, setFriendlyCampaignName] = useState<string>();
	const adtBarRef = useRef<HTMLDivElement>(null);
	const theme = useSelector((state: any) => state.theme);
	const agentType = useSelector((state: any) => state.agentType);

	//Init...
	useEffect(() => {
		postMessage({ request: "CallStartedAdtData" });
	}, []);

	useEffect(() => {
		if (!callStartedAdtData || !callStartedAdtData.campaignName || typeof callStartedAdtData.campaignName !== "string") {
			setCallColor(undefined);
			setFriendlyCampaignName(undefined);
			return;
		}

		if (callStartedAdtData.campaignName.includes("Lightning Connect")) {
			setCallColor("#d11aff"); //purple
			setFriendlyCampaignName("Lightning Connect");
		} else if (callStartedAdtData.campaignName.includes("Retention")) {
			setCallColor("#ff1a1a"); //red
			setFriendlyCampaignName("Retention");
		} else if (
			(callStartedAdtData.campaignName.includes("Inbound") || callStartedAdtData.campaignName.includes("Transfer")) &&
			callStartedAdtData.campaignName.includes("IRRRL")
		) {
			setCallColor("#4B5320"); //army green
			setFriendlyCampaignName(callStartedAdtData.campaignName);
		} else if (callStartedAdtData.campaignName.includes("Inbound")) {
			setCallColor("#00cc00"); //green
			setFriendlyCampaignName(callStartedAdtData.campaignName);
		} else if (callStartedAdtData.campaignName.includes("IRRRL")) {
			setCallColor("#827B60"); //army brown
			setFriendlyCampaignName("VA-IRRRL");
		} else if (callStartedAdtData.campaignName.includes("FHA")) {
			setCallColor("#012B53"); //yellow
			setFriendlyCampaignName("FHA");
		} else if (callStartedAdtData.campaignName.includes("Transfer")) {
			if (callStartedAdtData.campaignName.includes("Spanish")) {
				setCallColor("#32a88d"); // teal
				setFriendlyCampaignName("Spanish Transfer");
			} else {
				if (callStartedAdtData.campaignName.includes("Transfer to LO")) {
					setFriendlyCampaignName(customer?.rawContactObj?.fields?.[FieldKeys.VENDOR] ?? callStartedAdtData.campaignName);
				}
				setCallColor("#ffcc00"); //yellow
			}
		} //8a2be2
		else if (callStartedAdtData.campaignName) {
			//Outbound
			setCallColor("#3399ff"); //blue
			if (callStartedAdtData.campaignName.includes("Follow Up")) {
				setFriendlyCampaignName("Follow Up");
			} else if (callStartedAdtData.campaignName.includes("Manual Outbound")) {
				setFriendlyCampaignName("Manual Outbound");
			} else {
				setFriendlyCampaignName(callStartedAdtData.campaignName);
			}
		}

		if (callStartedAdtData.campaignName.includes("NONEWC")) {
			setFriendlyCampaignName("Credit Trigger");
		} else if (callStartedAdtData.campaignName.includes("EWC")) {
			setFriendlyCampaignName(customer?.rawContactObj?.fields?.[FieldKeys.VENDOR] ?? callStartedAdtData.campaignName);
		} else if (callStartedAdtData.campaignName.includes("SharkTank")) {
			setFriendlyCampaignName("SharkTank");
		} else if (callStartedAdtData.campaignName.includes("MissedOpportunity")) {
			setCallColor("#ff500a");
			setFriendlyCampaignName("Missed Transfer Callback");
		} else if (callStartedAdtData.campaignName.includes("SPANISH")) {
			setFriendlyCampaignName("Spanish");
		}
	}, [callStartedAdtData, customer?.rawContactObj?.fields]);


	useEffect(() => {
		if (adtBarRef.current) {
			const observer = new ResizeObserver(() => {
				setAdtBarHeight(adtBarRef.current?.clientHeight ?? 0);
			});

			setAdtBarHeight(adtBarRef.current?.clientHeight ?? 0);
			observer.observe(adtBarRef.current);

			return () => observer.disconnect();
		}
	}, [setAdtBarHeight]);

	/**
	 * Get Call Status Text -- func
	 */
	const getCallStatusText = useCallback(() => {
		switch (callStatus) {
			case "ONCALL":
				return (
					<span style={{ color: "green", WebkitTextStrokeColor: "black", WebkitTextStrokeWidth: 0.5 }}>
						{friendlyCampaignName ?? callStartedAdtData?.campaignName ?? "Call Started"}:
					</span>
				);
			case "WRAPUP":
				return <span style={{ color: "red" }}>Call Ended:</span>;
			default:
				return "Caller:";
		}
	}, [callStartedAdtData?.campaignName, friendlyCampaignName, callStatus]);

	//Return
	return (
		<>
			{["LO", "CCS"].includes(agentType) && (
				<HeaderContainer>
					<Productivity />
				</HeaderContainer>
			)}
			<CheckboxContainer ref={adtBarRef} $callStatus={callStatus} $color={callColor} $theme={theme}>
				<div>
					{callStartedAdtData?.contactDisplayName ? (
						<div style={{ fontSize: 22, textAlign: "center" }}>
							{getCallStatusText()}{" "}
							<strong>
								{isNaN(callStartedAdtData.contactDisplayName)
									? callStartedAdtData.contactDisplayName
									: formatPhoneNumber(callStartedAdtData.contactDisplayName)}
							</strong>
							{isNaN(callStartedAdtData.contactDisplayName) &&
								customer?.calledFromNumber &&
								` ${formatPhoneNumber(customer.calledFromNumber)}`}
						</div>
					) : (
						<>
							<img src={pjmc} style={{ height: 50, width: "auto" }} />
							&nbsp;&nbsp; Perry Johnson Mortgage
						</>
					)}
				</div>
				<div />
				<div style={{ display: "flex", alignItems: "center", columnGap: 10, justifyContent: "space-between" }}>
					<div />
					<IdeasModal />
					<Settings />
				</div>
			</CheckboxContainer>
		</>
	);
}

export default forwardRef(Header);

/**
 * StyledComponent
 */
const HeaderContainer = styled.header`
	& {
		text-align: center;
	}
`;

/**
 * StyledComponent
 */
const CheckboxContainer = styled.div<{ $color?: string; $callStatus?: string; $theme: string }>`
	${(props) => {
		return props.$color && props.$callStatus !== "WRAPUP"
			? `background-color: ${props.$color} !important;`
			: `background-color: ${props.$theme === "dark" ? "rgb(54, 56, 55)" : "rgb(240, 240, 240)"} !important;`;
	}}

	& {
		text-align: center;
	}

	& img {
		width: 100%;
		height: auto;
	}

	@media screen and (min-width: 620px) {
		& img {
			width: auto;
		}
	}

	.checkbox {
		opacity: 0;
		position: absolute;
	}

	.checkbox-label {
		background-color: #111;
		width: 50px;
		height: 26px;
		border-radius: 50px;
		position: relative;
		padding: 5px;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.fa-moon {
		color: #f1c40f;
	}

	.fa-sun {
		color: #f39c12;
	}

	.checkbox-label .ball {
		background-color: #fff;
		width: 22px;
		height: 22px;
		position: absolute;
		left: 2px;
		top: 2px;
		border-radius: 50%;
		transition: transform 0.2s linear;
	}

	.checkbox:checked + .checkbox-label .ball {
		transform: translateX(24px);
	}

	position: sticky;
	top: 0;
	z-index: 6;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	.checkbox-wrapper {
		display: flex;
		justify-content: center;
	}

	& > * {
		flex: 1;
	}
`;
