import moment from "moment";
import React, { useState, useEffect } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { eventEmitter, notReadyStatePayload, postMessage } from "../../utils";
import ConfirmButton from "../ConfirmButton";
import { FieldKeys } from "../hooks/useContactFields";

/**
 * Enumerable Schedule Type
 */
enum ScheduleType {
	NOW = "Now",
	SCHEDULED = "Scheduled",
}

/**
 * CallbackModal
 * @constructor
 */
export default function CallbackModal() {
	const [showModal, setShowModal] = useState(false);
	const [scheduleType, setScheduleType] = useState<ScheduleType>();
	const [date, setDate] = useState<string>("");
    const customer = useSelector((state: any) => state.customer);

	/**
	 * Hide Modal -- func
	 */
	const hideModal = () => {
		setShowModal(false);
		setDate("");
        setScheduleType(undefined);
	};

	/**
	 * Confirm Call Back -- func
	 */
	const confirmCallback = () => {
		const formattedDate = date === "" ? date : moment(date).utc().toString();
		postMessage({ action: "SetCallback", value: formattedDate });
		setDate("");
		setShowModal(false);
	};

	//Init..
	useEffect(() => {
		const setCallbackTime = (data: boolean) => {
			setShowModal(data);
		};

		eventEmitter.on("setCallbackTime", setCallbackTime);

		return () => {
			eventEmitter.off("setCallbackTime", setCallbackTime);
		};
	}, []);

	//Return
	return (
		<>
			<Button className="transfer-button-full" onClick={() => setShowModal(true)} size="sm">
				Call Back
			</Button>
			<Modal centered onHide={hideModal} show={showModal}>
				<Modal.Header>
					<Modal.Title>Call {customer?.rawContactObj?.fields?.[FieldKeys.FIRST_NAME]} {customer?.rawContactObj?.fields?.[FieldKeys.LAST_NAME]} back</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ display: "flex", columnGap: 10 }}>
						<ConfirmButton
                            ConfirmIcon={() => <i className="fa-solid fa-phone-volume"></i>}
                            Icon={() => <span>Call Back Now</span>}
                            action={() => {
                                postMessage({ action: "ChangeReadyState", value: notReadyStatePayload() });
                                postMessage({ action: "SetDisposition", value: "disCallback" });
                                setTimeout(() => {
                                    postMessage({ action: "DialNumber", value: customer.calledFromNumber });
                                }, 2000)
                            }}
                            buttonStyles={{ width: 130 }}
                            confirmText="Are you sure you would like to call back now?"
                            tooltipText="Call Back Now"
                        />
						<Button onClick={() => setScheduleType(ScheduleType.SCHEDULED)}>Future Call Back</Button>
					</div>
					{scheduleType === ScheduleType.NOW && <></>}
					{scheduleType === ScheduleType.SCHEDULED && (
						<div style={{ marginTop: 15 }}>
							<p>Please set a date and a time for the callback to occur</p>
							<FormControl onChange={(e) => setDate(e.target.value)} type="datetime-local" />
							<p>
								Timezone: <strong>{new Date().toTimeString().slice(9)}</strong>
							</p>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={hideModal} variant="secondary">
						Close
					</Button>
					{scheduleType === ScheduleType.SCHEDULED && (
						<Button onClick={confirmCallback} variant="primary">
							Confirm
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}