import { useEffect } from "react";
import {eventEmitter} from "../../utils";

/**
 * useFive9AgentSettingModal
 * Simple Five 9 Agent Settings Popup window.
 */
export default function useFive9AgentSettingModal() {
	//Init
	useEffect(() => {
		eventEmitter.on("showFive9AgentSettings", showModalHandler);

		return () => {
			eventEmitter.off("showFive9AgentSettings", showModalHandler);
		};
	}, []);

	/**
	 * Show Modal Handler -- func
	 */
	const showModalHandler = () => {
		//Open settings tab
		var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
		let URL = "https://app.five9.com/clients/integrations/adt.main.html?dialogName=agent-options&title=Five9%20Adapter%20-%20Settings%20-%20Chrome&f9verticalthreshold=300px#dialog/showAgentOptions";
		window.open(URL, "_blank", strWindowFeatures);
	};
}