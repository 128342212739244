import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {eventEmitter, formatPhoneToDigitsOnly, notReadyStatePayload, postMessage, readyStatePayload} from "../utils";
import ConfirmButton from "./ConfirmButton";
import Dispositions from "./Dispositions";
import CallbackModal from "./modals/CallbackModal";
import {setIsRetryingCallCustomer} from "../redux/globalSlice";

/**
 * ActionButtons
 * @constructor
 */
export default function ActionButtons() {
	const environment = useSelector((state: any) => state.environment);
	const customer = useSelector((state: any) => state.customer);
	const agentType = useSelector((state: any) => state.agentType);
	const callStatus = useSelector((state: any) => state.callStatus);
	const readyState = useSelector((state: any) => state.readyState);
	const isRetryingCallCustomer = useSelector((state: any) => state.isRetryingCallCustomer);

	const isReady = readyState?.currentState?.readyChannels?.length > 0;
	const call = useSelector((state: any) => state.call);
	const [contactInfo, setContactInfo] = useState<any>();
	const lo = call?.LoName;
	const [transferInProcess, setTransferInProcess] = useState(false);
	const [showDeadAirActionButtons, setShowDeadAirActionButtons] = useState(false);
	const [showSidebar, setShowSidebar] = useState(true);
	const transferConnected = lo && lo !== "(Unknown LO Name)";
	const dispatch = useDispatch();

	console.log("[INFO] EVENT - ActionButtons showDeadAirActionButtons", showDeadAirActionButtons);
	//console.log("[INFO] EVENT - ActionButtons showSidebar", showSidebar);

	/**
	 * Record and set the disposition -- func
	 * @param e
	 */
	function recordAndSetDisposition(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

		let dispo = e.currentTarget.value;
		let recordType = "";
		switch (e.currentTarget.value) {
			case "disEndlessRinging":
				dispo = "disVoicemail";
				recordType = "EndlessRinging"
				break;
			case "disSilence":
				dispo = "disDeadAir";
				recordType = "Silence"
				break;
			case "disVoicemail":
				recordType = "Voicemail"
				break;
			default:
				//..
				break;
		}

		//Make API CALL...

		//Save record
		saveRecord(recordType)

		//Set Dispo
		saveDisposition(dispo);

	}

	/**
	 * Save record to DB
	 * @param type
	 */
	function saveRecord(type:string) {
		let obj = {
			Call_ID: call.call_id,
			DeadAirType: type
		};
		//Log
		console.log("[INFO] EVENT - saveRecord | SaveCallData saving...", obj);
		//Make API Call
		postMessage({ action: "SaveCallData", value: obj});
	}

	/**
	 * callStartedAdtData redux
	 */
	const callStartedAdtData = useSelector((state: any) => state.callStartedAdtData);

	/**
	 * Save disposition -- func
	 * @param type
	 */
	function saveDisposition(type:string) {

		//console.log("INFO: isRetryingCallCustomer is set to:", isRetryingCallCustomer);
		if(type === 'disDeadAir'
			&& isRetryingCallCustomer === false
			&& !callStartedAdtData.campaignName.includes("Lightning Connect")) {
			//console.log("INFO: isRetryingCallCustomer is set to false! Attempt call back!");
			//Retry call back...
			retryCallback();
		} else {
			//Act normally...
			postMessage({ action: "SetDisposition", value: type });
		}

		//Hide sidebar
		setShowSidebar(false);

		//Reset
		//If this call is retrying to call set this to false
		if(isRetryingCallCustomer) {
			dispatch(setIsRetryingCallCustomer(false));
		}
	}

	function getCurrentTimestamp() {
		const now = new Date();
		const pad = (num: number) => num.toString().padStart(2, '0');
		const padMilliseconds = (num: number) => num.toString().padStart(3, '0');
		return `${pad(now.getMonth() + 1)}/${pad(now.getDate())}/${now.getFullYear()} ` +
			   `${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}.` +
			   `${padMilliseconds(now.getMilliseconds())}`;
	  }

	/**
	 * Set Disposition -- func
	 * @param e
	 */
	function setDisposition(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

		//save it
		saveDisposition(e.currentTarget.value);
	}

	/**
	 * Retry Calling Customer -- func
	 * Sets dispo. to "disDeadAir"
	 */
	function retryCallback() {

		//Get number
		//Get customer from handler prop
		const number = customer.calledFromNumber

		console.log(`${getCurrentTimestamp()} - [EVENT] - TRY CALLING BACK`);
		console.log(`${getCurrentTimestamp()} - [EVENT] - TRY CALLING BACK (isReady)`, isReady);
		console.log(`${getCurrentTimestamp()} - [EVENT] - TRY CALLING BACK (readyState)`, readyState);
		console.log(`${getCurrentTimestamp()} - [EVENT] - TRY CALLING BACK (attempting..)`);
		//..
		if(true){
			//Always set to not ready
			console.log(`${getCurrentTimestamp()} - [EVENT] - TRY CALLING BACK (readyState)`, readyState);
			console.log(`${getCurrentTimestamp()} - [EVENT] - TRY CALLING BACK (ChangeReadyState)`, "notReadyStatePayload()");
			postMessage({ action: "ChangeReadyState", value: notReadyStatePayload() });
		}
		//Set disposition...
		//Wait one second so status change goes through
		setTimeout(() => {
			postMessage({ action: "SetDisposition", value: "disDeadAir" });
		}, 1000);


		setTimeout(() => {
			console.log(`${getCurrentTimestamp()} - [EVENT] - TRY CALLING BACK (DialNumber)`, number)
            postMessage({ action: "DialNumber", value: {number:formatPhoneToDigitsOnly(number), campaignId:"300000000000753" } });
			//..
			setTimeout(() => {
				//Put back into ready status
				console.log(`${getCurrentTimestamp()} - [EVENT] - TRY CALLING BACK (isReady)`, isReady)
				if(isReady){
					console.log(`${getCurrentTimestamp()} - [EVENT] - TRY CALLING BACK (readyState)`, readyState);
					console.log(`${getCurrentTimestamp()} - [EVENT] - TRY CALLING BACK (ChangeReadyState)`, "readyStatePayload()");
					postMessage({ action: "ChangeReadyState", value: readyStatePayload() });
				}
			}, 2000);


			dispatch(setIsRetryingCallCustomer(true));

		}, 2000);
	}

	/**
	 * Transfer Clicked -- func
	 * @param e
	 */
	const transferClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setTransferInProcess(true);
		const obj = structuredClone(contactInfo);
		let campaignId = environment.campaignId;

		switch (e.currentTarget.value) {
			case "Refinance":
				obj.fields["300000000001021"] = "Refinance";
				break;
			case "Purchase":
				obj.fields["300000000001021"] = "Purchase";
				break;
			case "Spanish":
				postMessage({
					action: "CallTransferSpanish",
				});
				return;
			default:
				break;
		}

		postMessage({
			action: "SaveContactAndTransferCall",
			value: {
				payload: obj,
				params: {
					value: campaignId,
					name: e.currentTarget.value,
				},
			},
		});
	};

	//Init
	useEffect(() => {
		if (transferConnected || callStatus !== "ONCALL") {
			setTransferInProcess(false);
		}
	}, [transferConnected, callStatus]);

	useEffect(() => {
		const loUnavailable = () => {
			setTransferInProcess(false);
		};

		const setTransferProcess = (complete?: boolean) => {
			setTransferInProcess(!complete);
		};

		eventEmitter.on("showLoUnavailable", loUnavailable);
		eventEmitter.on("transferProcessComplete", setTransferProcess);

		return () => {
			eventEmitter.off("showLoUnavailable", loUnavailable);
			eventEmitter.off("transferProcessComplete", setTransferProcess);
		};
	}, []);

	useEffect(() => {
		if (customer === null) {
			//Reset
			setShowSidebar(true);
			setContactInfo(undefined);
		}
	}, [customer]);

	useEffect(() => {
		if (customer?.rawContactObj) {
			setContactInfo({ ...customer.rawContactObj });
		}
	}, [customer?.rawContactObj]);

	useEffect(() => {
		setShowDeadAirActionButtons(isRetryingCallCustomer);
	}, [isRetryingCallCustomer]);

	//Return...
	return showSidebar && !!call && (!call?.LoName || call.LoName === "(Unknown LO Name)") ? (
		<ActionButtonsCentered>
			<ActionButtonsContainer>
				{( showDeadAirActionButtons ) &&
					<div className={"transfer-button-container"}>
						<Button
							className="transfer-button-full"
							disabled={!customer}
							onClick={recordAndSetDisposition}
							size="sm"
							style={{ height: 80 }}
							value="disVoicemail"
							variant="primary">
							Voicemail
						</Button>
						<Button
							className="transfer-button-full"
							disabled={!customer}
							onClick={recordAndSetDisposition}
							size="sm"
							style={{ height: 80 }}
							value="disSilence"
							variant="primary">
							Silence
						</Button>
						<Button
							className="transfer-button-full"
							disabled={!customer}
							onClick={recordAndSetDisposition}
							size="sm"
							style={{ height: 80 }}
							value="disEndlessRinging"
							variant="primary">
							Endless Ringing
						</Button>
						<br />
						{/*RESET BTN */}
						<Button
							className="transfer-button-full"
							disabled={!customer}
							onClick={(e) => {setShowDeadAirActionButtons(false)}}
							size="sm"
							style={{ height: 80 }}
							value="disVoicemail"
							variant="primary">
							Client Answer
						</Button>
					</div>
				}
				{!showDeadAirActionButtons &&
					<div className="transfer-button-container">
						One-Click Dispositions
						<div className="transfer-button-group">
							<Button
								className="transfer-button-half"
								disabled={!customer}
								onClick={setDisposition}
								size="sm"
								style={{height: 70}}
								value="disVoicemail"
								variant="primary">
								Voicemail
							</Button>
							<Button
								className="transfer-button-half"
								disabled={!customer}
								onClick={(e) => { isRetryingCallCustomer === true ? setShowDeadAirActionButtons(true) : setDisposition(e) }}
								size="sm"
								style={{height: 70}}
								value="disDeadAir"
								variant="primary">
								Dead Air / Endless Ringing - No VM
							</Button>
							<Button
								className="transfer-button-half"
								disabled={!customer}
								onClick={setDisposition}
								size="sm"
								style={{height: 70}}
								value="disWrongNumber"
								variant="primary">
								Wrong #
							</Button>
							<ConfirmButton
								ConfirmIcon={() => <i className="fa-solid fa-phone-slash"></i>}
								Icon={() => <span>Not Interested</span>}
								action={() => setDisposition({currentTarget: {value: "disNotInterested"}} as any)}
								buttonStyles={{height: 70, width: "100%"}}
								className="transfer-button-half"
								confirmText="Are you sure you would like to disposition as Not Interested?"
								size="sm"
								tooltipText="Not Interested"
							/>
							<ConfirmButton
								ConfirmIcon={() => <i className="fa-solid fa-phone-slash"></i>}
								Icon={() => <span>Do Not Call - All #s</span>}
								action={() => setDisposition({currentTarget: {value: "disDNC"}} as any)}
								className="transfer-button-full"
								confirmText="Are you sure you would like to disposition as Do Not Call - All #s?"
								size="sm"
								tooltipText="Do Not Call - All #s"
							/>
							<ConfirmButton
								ConfirmIcon={() => <i className="fa-solid fa-phone-slash"></i>}
								Icon={() => <span>Do Not Call - Current Number</span>}
								action={() => setDisposition({currentTarget: {value: "disDNCCurrentNumber"}} as any)}
								className="transfer-button-full"
								confirmText="Are you sure you would like to disposition as Do Not Call - Current Number?"
								size="sm"
								tooltipText="Do Not Call - Current Number"
							/>
							{agentType === "LO" && (
								<ConfirmButton
									ConfirmIcon={() => <i className="fa-solid fa-phone-slash"></i>}
									Icon={() => <span>No Benefit</span>}
									action={() => setDisposition({currentTarget: {value: "disNoBenefit"}} as any)}
									className="transfer-button-full"
									confirmText="Are you sure you would like to disposition as No Benefit?"
									size="sm"
									tooltipText="No Benefit"
								/>
							)}
						</div>
					</div>}

				{agentType === "CCS" && (
					<div className="transfer-button-container">
						{transferInProcess && callStatus === "ONCALL" ? (
							<>Transfer in Process...</>
						) : (
							<>
								Transfer Options
								<div className="transfer-button-group">
									<Button
										className="transfer-button-full"
										disabled={!customer}
										onClick={transferClicked}
										size="sm"
										type="button"
										value="Refinance"
										variant="primary">
										Refinance
									</Button>
									<Button
										className="transfer-button-full"
										disabled={!customer}
										onClick={transferClicked}
										size="sm"
										type="button"
										value="Purchase"
										variant="primary">
										Purchase
									</Button>
									<Button
										className="transfer-button-full"
										disabled={!customer}
										onClick={transferClicked}
										size="sm"
										type="button"
										value="Spanish"
										variant="primary">
										Spanish
									</Button>
								</div>
							</>
						)}
					</div>
				)}
				{(agentType === "LO" || agentType === "CCS") && (
					<div className="transfer-button-container">
						Lead Actions
						<div className="transfer-button-group">
							{(agentType === "LO") && (
								<Button
									className="transfer-button-full"
									disabled={!customer}
									onClick={() => postMessage({action: "TakeLead"})}
									size="sm"
									variant="primary">
									Take Lead
								</Button>
							)}
							<CallbackModal/>
							{(agentType === "LO") && (
								<Button
									className="transfer-button-full"
									disabled={!customer}
									onClick={() => postMessage({action: "SmsInfo"})}
									size="sm"
									variant="primary">
									SMS Agent Info
								</Button>
							)}
						</div>
					</div>
				)}
				<div className="transfer-button-container">
					Call Actions
					<div className="transfer-button-group">
						<Button
							className="transfer-button-full"
							disabled={callStatus !== "ONCALL"}
							onClick={() => postMessage({action: "EndCall"})}
							size="sm"
							variant={callStatus === "ONCALL" ? "danger" : "secondary"}>
							{callStatus === "WRAPUP" ? "Call Ended" : "End Call"}
						</Button>
						{agentType === "LO" && (
							<Button
								className="transfer-button-full"
								disabled={!customer}
								onClick={transferClicked}
								size="sm"
								type="button"
								value="Spanish"
								variant="primary">
								Spanish Transfer
							</Button>
						)}

						{
							!showDeadAirActionButtons
							&&
							<Dispositions buttonSize="sm"/>
						}
					</div>
				</div>
			</ActionButtonsContainer>
		</ActionButtonsCentered>
	) : (
		<></>
	);
}

/**
 * StyledComponent
 */
const ActionButtonsCentered = styled.div({
	display: "flex",
	justifyContent: "center",
});

/**
 * StyledComponent
 */
const ActionButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.transfer-button-group {
		display: flex;
		margin: 0px 10px;
		flex-wrap: wrap;
		column-gap: 10px;
		row-gap: 10px;
		width: 100%;
	}

	.transfer-button-third {
		width: calc(33% - 6px);
	}

	.transfer-button-half {
		width: calc(50% - 5px);
	}

	.transfer-button-full {
		width: 100%;
	}

	.transfer-button-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 10px;
		flex: 1;
		padding: 10px;
		width: 100%;
	}
`;