import React, { useState, useEffect } from "react";
import { Accordion, Button, ButtonGroup, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import { setHotlist } from "../../../redux/globalSlice";
import { postMessage, formatPhoneToDigitsOnly, eventEmitter, isCallableLead } from "../../../utils";
import { toast } from "react-toastify";
import RowWithDropdown from "./RowWithDropdown";
//import _ from "lodash";
//import useHotlistReminders from "../../hooks/useHotlistReminders";

/**
 * Hotlist
 * @constructor
 */
const Hotlist = () => {
	const hotlist = useSelector((state: any) => state.hotlist);
	const [search, setSearch] = useState("");
	const [filteredHotlist, setFilteredHotlist] = useState<any[]>();
	const [callableLeads, setCallableLeads] = useState(false);
	const [refinanceLoans, setRefinanceLoans] = useState(false);
	const [purchaseLoans, setPurchaseLoans] = useState(false);
	const dispatch = useDispatch();
	const customer = useSelector((state: any) => state.customer);
	const readyFollowUp = useSelector((state: any) => state.readyFollowUp);

	/**
	 * Update Local Note -- func
	 * @param note
	 * @param id
	 */
	const updateLocalNote = (note: string, id: string) => {
		const newHotlist = hotlist.map((item: any) => {
			if (item.id === id) {
				return {
					...item,
					properties: {
						...item.properties,
						notes: note,
					},
				};
			}

			return item;
		});

		dispatch(setHotlist(newHotlist));
	};

	/**
	 * Sort Hotlist -- func
	 * @param prop
	 * @param order
	 */
	const sortHotlist = (prop: string, order: "ASC" | "DESC") => {
		if (filteredHotlist) {
			const filtered: any[] = [...filteredHotlist];
			filtered.sort((a: any, b: any) => {
				let aValue = a.properties?.[prop];
				let bValue = b.properties?.[prop];

				if (aValue === bValue) return 0;
				if (!aValue) return 1;
				if (!bValue) return -1;

				if (prop === "five9_last_dial_start_timestamp" || prop === "callback_appointment_ts") {
					aValue = new Date(aValue);
					bValue = new Date(bValue);
				}

				if (order === "ASC") {
					return aValue > bValue ? 1 : -1;
				} else {
					return aValue < bValue ? 1 : -1;
				}
			});

			setFilteredHotlist(filtered);
		} else {
			const sorted: any[] = [...hotlist];
			sorted.sort((a: any, b: any) => {
				let aValue = a.properties?.[prop];
				let bValue = b.properties?.[prop];

				if (prop === "five9_last_dial_start_timestamp" || prop === "callback_appointment_ts") {
					aValue = new Date(aValue);
					bValue = new Date(bValue);
				}

				if (aValue === bValue) return 0;
				if (!aValue) return 1;
				if (!bValue) return -1;

				if (order === "ASC") {
					return a.properties?.[prop] > b.properties?.[prop] ? 1 : -1;
				} else {
					return a.properties?.[prop] < b.properties?.[prop] ? 1 : -1;
				}
			});

			dispatch(setHotlist(sorted));
		}
	};

	/**
	 * Set Reserved Lead -- func
	 * @param id
	 * @param reserved
	 */
	const setReservedLead = (id: string, reserved: boolean) => {
		const newHotlist = hotlist.map((item: any) => {
			if (item.id === id) {
				return {
					...item,
					properties: {
						...item.properties,
						reserved_lead: reserved,
					},
				};
			}

			return item;
		});

		dispatch(setHotlist(newHotlist));
	};

	//Init...
	useEffect(() => {
		const checkHotlist = (callStartedAdtData: any) => {
			const customerOnHotlist = hotlist.find((item: any) => {
				const phone =
					item.properties?.five9_last_dialed_number ||
					item.properties?.phone ||
					item.properties?.phone_number_2 ||
					item.properties?.phone_number_3 ||
					undefined;

				return (
					formatPhoneToDigitsOnly(phone) === formatPhoneToDigitsOnly(customer?.calledFromNumber) && !!callStartedAdtData
				);
			});

			if (customerOnHotlist) {
				const scheduledCallbackWithin10Mins = customerOnHotlist.properties?.callback_appointment_ts
					? new Date(customerOnHotlist.properties.callback_appointment_ts) < new Date(Date.now() + 10 * 60 * 1000)
					: false;

				const newHotlist = hotlist.map((item: any) => {
					if (item.id === customerOnHotlist.id) {
						const properties = {
							...item.properties,
							five9_last_dial_start_timestamp: new Date().toISOString().replace("Z", ""),
						};

						if (scheduledCallbackWithin10Mins) {
							properties.callback_appointment_ts = null;
						}

						return {
							...item,
							properties,
						};
					}

					return item;
				});

				dispatch(setHotlist(newHotlist));
			}
		};

		eventEmitter.on("callStarted", checkHotlist);

		return () => {
			eventEmitter.off("callStarted", checkHotlist);
		};
	}, [customer?.calledFromNumber, customer?.rawContactObj?.fields, dispatch, hotlist]);

	useEffect(() => {
		const removeFromHotlist = (data: any) => {
			if (data.HotlistRemoved) {
				const newHotlist = hotlist.filter((item: any) => item.id !== data.Id);
				dispatch(setHotlist(newHotlist));
				toast.success("Removed from Hotlist");
			} else {
				toast.error("Failed to remove from Hotlist");
			}
		};

		eventEmitter.on("hotlistRemoved", removeFromHotlist);

		return () => {
			eventEmitter.off("hotlistRemoved", removeFromHotlist);
		};
	}, [dispatch, hotlist]);

	useEffect(() => {
		if (hotlist) {
			let filtered = [...hotlist];

			if (callableLeads) {
				filtered = filtered.filter(isCallableLead);
			}

			if (refinanceLoans && purchaseLoans) {
				filtered = filtered.filter((item: any) => ["Refinance", "Purchase"].includes(item.properties?.loan_purpose));
			} else if (refinanceLoans) {
				filtered = filtered.filter((item: any) => item.properties?.loan_purpose === "Refinance");
			} else if (purchaseLoans) {
				filtered = filtered.filter((item: any) => item.properties?.loan_purpose === "Purchase");
			}

			if (search) {
				filtered = filtered.filter((item: any) => {
					return (
						`${item.properties?.firstname?.toLowerCase()} ${item.properties?.lastname?.toLowerCase()}`.includes(
							search.toLowerCase()
						) || item.properties?.five9_last_dialed_number?.includes(search)
					);
				});
			}

			setFilteredHotlist(filtered);
		}
	}, [callableLeads, hotlist, purchaseLoans, refinanceLoans, search]);

	useEffect(() => {
		setCallableLeads(readyFollowUp);
	}, [readyFollowUp]);

	//Return
	return (
		<HotlistContainer>
			<Accordion>
				<Accordion.Item eventKey="0">
					<Accordion.Header>
						<h1 style={{ textAlign: "center", width: "100%" }}>My Leads</h1>
					</Accordion.Header>
					<Accordion.Body>
						<div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", columnGap: 10 }}>
							<Form.Control
								onChange={(e) => setSearch(e.target.value)}
								placeholder="Search..."
								style={{ justifySelf: "flex-end", width: 300 }}
								type="text"
							/>
							<div>
								<Form.Check
									checked={callableLeads}
									inline
									label="Show Callable Leads"
									onClick={(e) => setCallableLeads(e.currentTarget.checked)}
									type="checkbox"
								/>
								<Form.Check
									checked={refinanceLoans}
									inline
									label="Show Refinance Loans"
									onClick={(e) => setRefinanceLoans(e.currentTarget.checked)}
									type="checkbox"
								/>
								<Form.Check
									checked={purchaseLoans}
									inline
									label="Show Purchase Loans"
									onClick={(e) => setPurchaseLoans(e.currentTarget.checked)}
									type="checkbox"
								/>
							</div>
							<Button disabled={readyFollowUp} onClick={() => postMessage({ request: "Hotlist" })}>
								<i className="fa fa-refresh"></i>
							</Button>
						</div>
						<hr />
						<Table hover>
							<thead>
								<tr>
									<th>
										Name&nbsp;&nbsp;
										<ButtonGroup>
											<Button name="firstname" onClick={(e) => sortHotlist(e.currentTarget.name, "DESC")} size="sm">
												<i className="fa-solid fa-arrow-up"></i>
											</Button>
											<Button name="firstname" onClick={(e) => sortHotlist(e.currentTarget.name, "ASC")} size="sm">
												<i className="fa-solid fa-arrow-down"></i>
											</Button>
										</ButtonGroup>
									</th>
									<th>
										Assigned Date &nbsp;&nbsp;
										<ButtonGroup>
											<Button
												name="hubspot_owner_assigneddate"
												onClick={(e) => sortHotlist(e.currentTarget.name, "ASC")}
												size="sm">
												<i className="fa-solid fa-arrow-up"></i>
											</Button>
											<Button
												name="hubspot_owner_assigneddate"
												onClick={(e) => sortHotlist(e.currentTarget.name, "DESC")}
												size="sm">
												<i className="fa-solid fa-arrow-down"></i>
											</Button>
										</ButtonGroup>
									</th>
									<th>
										Last Contacted &nbsp;&nbsp;
										<ButtonGroup>
											<Button
												name="five9_last_dial_start_timestamp"
												onClick={(e) => sortHotlist(e.currentTarget.name, "ASC")}
												size="sm">
												<i className="fa-solid fa-arrow-up"></i>
											</Button>
											<Button
												name="five9_last_dial_start_timestamp"
												onClick={(e) => sortHotlist(e.currentTarget.name, "DESC")}
												size="sm">
												<i className="fa-solid fa-arrow-down"></i>
											</Button>
										</ButtonGroup>
									</th>
									<th>
										<span>Scheduled Callback</span>&nbsp;&nbsp;
										<ButtonGroup size="sm">
											<Button name="callback_appointment_ts" onClick={(e) => sortHotlist(e.currentTarget.name, "ASC")}>
												<i className="fa-solid fa-arrow-up"></i>
											</Button>
											<Button name="callback_appointment_ts" onClick={(e) => sortHotlist(e.currentTarget.name, "DESC")}>
												<i className="fa-solid fa-arrow-down"></i>
											</Button>
										</ButtonGroup>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{(filteredHotlist ?? hotlist)?.map((item: any) => (
									<RowWithDropdown
										key={item.id}
										item={item}
										setReservedLead={setReservedLead}
										updateLocalNote={updateLocalNote}
									/>
								))}
							</tbody>
						</Table>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</HotlistContainer>
	);
};

/**
 * StyledComponent
 */
const HotlistContainer = styled.div``;

export default Hotlist;