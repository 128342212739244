import React from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import { formatPhoneNumber, formatPhoneToDigitsOnly, getValidHubspotPhone, postMessage } from "../../utils";

/**
 * CallbackToast
 * @param properties
 * @param minutes
 * @constructor
 */
export default function CallbackToast({ properties, minutes }: { properties: any; minutes: number }) {
	const readyState = useSelector((state: any) => state.readyState);
	const isReady = readyState?.currentState?.readyChannels?.length > 0;

	//Return
	return (
		<CallbackToastContainer>
			<div>
				Callback for{" "}
				<strong>
					{properties.firstname} {properties.lastname}
				</strong>
			</div>
			<strong>{minutes} Minutes</strong>
			<OverlayTrigger
				overlay={
					<Tooltip className="in" placement="top">
						{formatPhoneNumber(properties.five9_last_dialed_number)}
					</Tooltip>
				}
				placement="top">
				<Button
					disabled={isReady || !getValidHubspotPhone({ properties })}
					onClick={(e) => {
						postMessage({
							action: "DialNumber",
							value: formatPhoneToDigitsOnly(getValidHubspotPhone({ properties })),
						});
					}}
                    size="sm"
					variant="success">
					Dial
				</Button>
			</OverlayTrigger>
		</CallbackToastContainer>
	);
}

/**
 * StyledComponent
 */
const CallbackToastContainer = styled.div`
	text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
	color: black;
`;