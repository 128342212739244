import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Button, Modal, Form } from "react-bootstrap";
import { getName, postMessage } from "../../utils";
import { useSelector } from "react-redux";

/**
 * ReferModal
 * @param item
 * @constructor
 */
export default function ReferModal({ item }: { item: any }) {
	const [showModal, setShowModal] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState("ANY");
    const agent = useSelector((state: any) => state.agent);
    const referralListItems = useSelector((state: any) => state.referralListItems);

	/**
	 * Delete from row
	 */
	const deleteFromRow = () => {
		//setConfirmRemove();
		//console.log('EVENT: item removed from hot-list');
		document.dispatchEvent(
			new CustomEvent("hubWindowPostMessage", {
				detail: { HotlistRemoved: true, Id: item.id },
			})
		);
	}

	/**
	 * Hide modal
	 */
	const hideModal = () => {
		setShowModal(false);
	};

	/**
	 * Refer item
	 */
	const refer = () => {
        //Send API Call
		postMessage({ action: "ReferClient", value: { HubSpotID: item.id, Agent: agent.email, ReferTo: selectedAgent } });
		//Remove item from table row
		deleteFromRow();
		//Close modal
		hideModal();
	};

	/**
	 * {
	 *	"AgentEmail": "sample@example.com",
	 *  "AgentName": "Name"
	 * }
	 */

	//Return
	return (
		<>
			<OverlayTrigger
				overlay={
					<Tooltip className="in" placement="top">
						Refer client
					</Tooltip>
				}
				placement="top">
				<Button onClick={() => setShowModal(true)}>
					<i className="fa-solid fa-money-bill-transfer"></i>
				</Button>
			</OverlayTrigger>
			<Modal centered onHide={() => hideModal()} show={showModal}>
				<Modal.Header closeButton>
					<Modal.Title>Refer {getName(item)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Label>Refer To</Form.Label>
						<Form.Select onChange={e => setSelectedAgent(e.target.value)} value={selectedAgent}>
							<option value="ANY">Any Agent</option>
							{referralListItems.map(({ AgentEmail, AgentName } : {AgentEmail:any, AgentName:any}, index:any) => <option key={AgentEmail} value={AgentEmail} >{AgentName}</option>)}
						</Form.Select>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => hideModal()} variant="secondary">
						Close
					</Button>
					<Button onClick={refer} variant="success">
						Refer
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
