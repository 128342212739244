import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice({
	name: "globalState",
	initialState: {
		customer: undefined,
		lcCustomer: undefined,
		agent: undefined,
		//AgentOptions
		agentOptions: undefined,
		call: undefined,
		callLogs: undefined,
		lisa: undefined,
		notes: undefined,
		productiveMins: 0,
		callStartedAdtData: undefined,
		agentType: undefined,
		isTransferred: undefined,
		transferAttempts: 0,
		ccsStats: undefined,
		loStats: undefined,
		callStatus: undefined,
		clientWeather: undefined,
		theme: undefined,
		readyState: undefined,
		readyFollowUp: false,
		isRetryingCallCustomer: false,
		customerActionScriptInput: [],
		isLOConnected: false,
		//Default to true TODO t: maybe change this to isNoteEditorDisabled and reverse the polarity
		isNoteEditorReady: true,
		hotlist: [],
		referralListItems: [],
		environment: (() => {
			const { href, hostname, origin } = location;
			const isLocal = href.startsWith("file:") || hostname === "localhost" || href.includes("127.0.0.1");
			const isDev = origin.includes("azurestaticapps");
			const azureUrl = "https://storagepjmortgage.blob.core.windows.net/scripts";

			return {
				arpsUrl: isLocal ? "scripts/arps.json" : `${azureUrl}/arps.json`,
				campaignId: isLocal || isDev ? "300000000000122" : "300000000000123",
				isLocal,
				isDev,
				azureUrl
			};
		})(),
	},
	reducers: {
		setCustomer: (state, action) => {
			state.customer = action.payload;
		},
		setCallLogs: (state, action) => {
			state.callLogs = action.payload;
		},
		setLisa: (state, action) => {
			state.lisa = action.payload;
		},
		setNotes: (state, action) => {
			state.notes = action.payload;
		},
		setProductiveMins: (state, action) => {
			state.productiveMins = action.payload;
		},
		setCallStartedAdtData: (state, action) => {
			state.callStartedAdtData = action.payload;
		},
		setAgentType: (state, action) => {
			state.agentType = action.payload;
		},
		setIsTransferred: (state, action) => {
			state.isTransferred = action.payload;
		},
		setTransferAttempts: (state, action) => {
			if(action.payload === 0) {
				state.transferAttempts = action.payload;
			} else {
				state.transferAttempts = state.transferAttempts + 1;
			}
		},
		setCall: (state, action) => {
			state.call = action.payload;
		},
		setAgent: (state, action) => {
			state.agent = action.payload;
		},
		//Set AgentOptions
		setAgentOptions: (state, action) => {
			console.log("[INFO] EVENT - globalSlice | setAgentOptions");
			state.agentOptions = action.payload;
		},
		setCcsStats: (state, action) => {
			state.ccsStats = action.payload;
		},
		setLoStats: (state, action) => {
			state.loStats = action.payload;
		},
		setCallStatus: (state, action) => {
			state.callStatus = action.payload;
		},
		setClientWeather: (state, action) => {
			state.clientWeather = action.payload;
		},
		setTheme: (state, action) => {
			state.theme = action.payload;
		},
		setReadyState: (state, action) => {
			state.readyState = action.payload;
		},
		setLcCustomer: (state, action) => {
			state.lcCustomer = action.payload;
		},
		setReferralListItems: (state, action) => {
			state.referralListItems = action.payload;
		},
		setIsRetryingCallCustomer: (state, action) => {
			state.isRetryingCallCustomer = action.payload;
		},
		setCustomerActionScriptInput: (state, action) => {
			state.customerActionScriptInput = action.payload;
		},
		setIsLOConnected: (state, action) => {
			state.isLOConnected = action.payload;
		},
		setIsNoteEditorReady: (state, action) => {
			state.isNoteEditorReady = action.payload;
		},
		setHotlist: (state, action) => {
			state.hotlist = action.payload;
		},
		setReadyFollowUp: (state, action) => {
			state.readyFollowUp = action.payload;
		},
		setAgentContact: (state, action) => {
			if (state.agent) {
				state.agent = { ...(state.agent as any), contact: action.payload };
			} else {
				state.agent = { contact: action.payload } as any;
			}
		},
	},
});

export const {
	setCustomer,
	setCallLogs,
	setLisa,
	setNotes,
	setProductiveMins,
	setCallStartedAdtData,
	setAgentType,
	setIsTransferred,
	setTransferAttempts,
	setCall,
	setAgent,
	setAgentOptions,
	setCcsStats,
	setLoStats,
	setCallStatus,
	setClientWeather,
	setTheme: setAppTheme,
	setReadyState,
	setLcCustomer,
	setIsRetryingCallCustomer,
	setIsLOConnected,
	setIsNoteEditorReady,
	setReferralListItems,
	setCustomerActionScriptInput,
	setHotlist,
	setReadyFollowUp,
	setAgentContact,
} = globalSlice.actions;

export default globalSlice.reducer;
