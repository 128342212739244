import React, { useCallback, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { styled } from "styled-components";
import { postMessage } from "../utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import poop from "../assets/poop-emoji.png";
import money from "../assets/money-emoji.png";
import star from "../assets/star-icon.png";

/**
 * CallSurvey
 * @param callFinishedAdtData
 * @param callFinishedCustomer
 * @constructor
 */
export default function CallSurvey({
	callFinishedAdtData,
	callFinishedCustomer,
}: {
	callFinishedAdtData: any;
	callFinishedCustomer: any;
}) {
	const agentType = useSelector((state: any) => state.agentType);
	const [feedback, setFeedback] = useState<string>();
	const [rating, setRating] = useState(0);

	const transferrer = callFinishedAdtData?.number?.startsWith("agent:")
		? callFinishedAdtData.number.split(":")[1]
		: "Unknown Agent";

	const ratings = useCallback(() => {
		const stars = [];

		for (let i = 1; i < 6; i++) {
			let src = star;

			if (i === 1) {
				src = poop;
			}

			if (i === 5) {
				src = money;
			}

			stars.push(
				<span key={i} className="rating" onClick={() => setRating(i)} style={{ opacity: i <= rating ? 1 : 0.5 }}>
					<img className="icon" src={src} />
				</span>
			);
		}

		return stars;
	}, [rating]);

	//Return
	return callFinishedAdtData && callFinishedCustomer ? (
		<CallSurveyContainer>
			<div style={{ fontSize: 16 }}>
				Call survey for <strong>{transferrer}</strong>
			</div>
			<div style={{ fontSize: 14, margin: "10px 0" }}>
				{agentType === "LO" && "Rate the performance of the CCS's transfer handoff of"}
				{agentType === "CCS" && "Rate the performance of the Loan Officer who accepted the transfer of"}{" "}
				<strong>
					{callFinishedCustomer?.first_name} {callFinishedCustomer?.last_name}
				</strong>
			</div>
			<div style={{ margin: "10px 0" }}>{ratings()}</div>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<textarea
					maxLength={250}
					onChange={(e) => setFeedback(e.target.value)}
					placeholder="Feedback.."
					rows={5}
					style={{ width: "100%" }}
					value={feedback}
				/>
				<Form.Text style={{ textAlign: "right" }}>{feedback?.length ?? 0} / 250</Form.Text>
			</div>
			<Button
				disabled={rating === 0}
				onClick={() => {
					postMessage({
						action: "SubmitFeedback",
						value: { rating, feedback, callId: callFinishedAdtData.callId, agentType: agentType === "LO" ? "CCS" : "LO" },
					});
					toast.dismiss();
				}}
				size="sm"
				variant="success">
				Submit
			</Button>
		</CallSurveyContainer>
	) : (
		<></>
	);
}

/**
 * StyledComponent
 */
const CallSurveyContainer = styled.div`
	text-align: center;

	.rating {
		opacity: 0.5;
		cursor: pointer;
	}

	.icon {
		height: 25px;
		width: auto;
	}
`;
