import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

/**
 * Leaderboards
 * @constructor
 */
const Leaderboards: React.FC = () => {
	const [showModal, setShowModal] = useState(false);
	const loStats = useSelector((state: any) => state.loStats);
	const agent = useSelector((state: any) => state.agent);

	/**
	 * Get Trophy -- func
	 * @param i
	 */
	const getTrophy = (i: number) => {
		switch (i) {
			case 0:
				return (
					<>
						<i className="fa-solid fa-trophy" style={{ color: "gold" }}></i>&nbsp;
					</>
				);
			case 1:
				return (
					<>
						<i className="fa-solid fa-trophy" style={{ color: "silver" }}></i>&nbsp;
					</>
				);
			case 2:
				return (
					<>
						<i className="fa-solid fa-trophy" style={{ color: "#CD7F32" }}></i>&nbsp;
					</>
				);
			default:
				return null;
		}
	};

	//Return
	return loStats?.ESigns?.length > 0 ? (
		<div>
			<Button onClick={() => setShowModal(true)} size="sm" variant="link">
				Leaderboard
			</Button>
			<Modal centered onHide={() => setShowModal(false)} show={showModal}>
				<Modal.Header closeButton>
					<Modal.Title>E-Signs Leaderboard - Last 7 Days</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row style={{ marginBottom: 5 }}>
							<Col xs={6}>
								<strong>Loan Officer</strong>
							</Col>
							<Col xs={3}>
								<strong>E-Signs</strong>
							</Col>
							<Col xs={3}>
								<strong>Revenue</strong>
							</Col>
						</Row>
						{loStats.ESigns.map((metric: any, i: number) => (
							<Row key={metric.LoanOfficer} style={{ marginTop: 5 }}>
								<Col xs={6}>
									{i + 1}. {getTrophy(i)}{" "}
									{i > -1 && i < 3 ? (
										<strong style={{ textDecoration: metric.LoanOfficer === (agent?.fullName || agent?.full_name) ? "underline" : undefined }}>
											{metric.LoanOfficer}
										</strong>
									) : (
										<span style={{ textDecoration: metric.LoanOfficer === (agent?.fullName || agent?.full_name) ? "underline" : undefined }}>
											{metric.LoanOfficer}
										</span>
									)}
								</Col>
								<Col xs={3}>{metric.ESigns}</Col>
								<Col xs={3}>
									<span style={{ color: "lightgreen", WebkitTextStrokeColor: "black", WebkitTextStrokeWidth: 0.5 }}>
										${Number(metric.TotalCompensation).toLocaleString()}
									</span>
								</Col>
							</Row>
						))}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setShowModal(false)} variant="secondary">
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	) : (
		<></>
	);
};

export default Leaderboards;