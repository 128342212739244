import _ from "lodash";
import React, {useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import {useSelector} from "react-redux";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import { postMessage } from "../utils";
import { FieldKeys } from "./hooks/useContactFields";

/**
 * SunEditor Toolbar Buttons -- Array
 */
const buttonList = [
	["bold", "italic", "underline", "strike"],
	["list", "align", "fontColor", "hiliteColor"],
];

/**
 * Notes
 * @constructor
 */
export default function Notes() {
	const isLOConnected = useSelector((state: any) => state.isLOConnected);
	const isNoteEditorReady = useSelector((state: any) => state.isNoteEditorReady);
	const customer = useSelector((state: any) => state.customer);
	const notes = useSelector((state: any) => state.notes);
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [note, setNote] = useState("");
	const saveAttemptCount = useRef(0);
	const canUpdate = useRef(true);
	const noteRef = useRef(note); // Initialize the ref with note


	//Init...
	// Fire when notes change
	useEffect(() => {
		console.log("[INFO] EVENT - notes watcher:", notes)
		console.log("[INFO] EVENT - canUpdate.current:", canUpdate.current)
		if (notes !== undefined) {
			console.log("[INFO] EVENT - changing canUpdate.current...", false)
			canUpdate.current = false;
			setNote(notes);
		}
		console.log("[INFO] EVENT - canUpdate.current:", canUpdate.current)
	}, [notes]);

	// Update the ref whenever note changes
	useEffect(() => {
		noteRef.current = note;
	}, [note]); // This effect only re-runs when `note` changes

	/**
	 * @type {React.MutableRefObject<SunEditorCore>} get type definitions for editor
	 */
	const editor:React.MutableRefObject<SunEditorCore|any> = useRef();

	// Effect to call updateNote when isLoConnected changes
	useEffect(() => {
		//Console log...
		console.log("[INFO] EVENT - Checking if notes needs to be saved...");
		console.log("[INFO] EVENT - isLOConnected", isLOConnected);
		//console.log("[INFO] EVENT - unsavedChanges", unsavedChanges);

		if (isLOConnected) {
			//console.log("[INFO] EVENT - isLOConnected", isLOConnected);
			//if(unsavedChanges) {

				//updateNote(noteRef.current); // Use the current value of note
				//forceUpdate.current = true;

				// Gets the contents of the suneditor
				// * not working during enabled codeView mode
				// onlyContents {Boolean}: Return only the contents of the body without headers when the "fullPage" option is true


			if(editor !== undefined && editor.current !== undefined) {
				try {
					var notes = editor.current.getContents(false);
					console.log("[INFO] EVENT - Getting note contents...", notes)

					console.log("[INFO] EVENT - Saving notes...");
					updateNote(notes);
				} catch (e) {
					console.error(e);
				}
			}


			//}
		}
	}, [isLOConnected]); // This effect only re-runs when `isLoConnected` changes

	/**
	 * Update Note -- func
	 * @param text
	 */
	async function updateNote(text: string) {
		postMessage({ action: "UpdateNote", value: { note: text } });
	}

	/**
	 * Debounced Search -- func
	 */
	const debouncedSearch = useRef(
		_.debounce(async (text) => {
			setUnsavedChanges(false);
			updateNote(text);
		}, 2000)
	).current;



	// The sunEditor parameter will be set to the core suneditor instance when this function is called
	const getSunEditorInstance = (sunEditor : SunEditorCore) => {
		console.log("[INFO] EVENT - getSunEditorInstance", sunEditor);
		editor.current = sunEditor;
	};

	/**
	 * Note Changed -- func
	 * @param newValue
	 */
	function noteChanged (newValue: string) {
		console.log("[INFO] Event - NOTE CHANGED:", newValue);
		console.log("[INFO] Event - saveAttemptCount", saveAttemptCount.current);
		//Do not fire the fist time, but every time after
		saveAttemptCount.current = saveAttemptCount.current + 1;
		if(
			saveAttemptCount.current > 1
		) {
			console.log("[INFO] Event - SAVING...");
			debouncedSearch.cancel();
			setUnsavedChanges(true);
			debouncedSearch(newValue);
		} else {
			console.log("[INFO] Event - NOT SAVING ON FIRST ATTEMPT...");
		}
	}

	//Logging...
	console.log("[INFO] EVENT - Init. notes notes:", notes);
	console.log("[INFO] EVENT - Init. notes note:", note);

	//Return
	return customer?.rawContactObj?.fields?.[FieldKeys.HUBSPOT_ID] ? (
		<div>
			<h1 style={{ textAlign: "center" }}>Live Shared Note</h1>
			<div>
				{/* for some reason suneditor's disable prop doesn't cause a re-render? */}
				{(customer) && (
					<SunEditor
						disable={(isLOConnected || !isNoteEditorReady)}
						getSunEditorInstance={getSunEditorInstance}
						height="300px"
						onChange={noteChanged}
						setContents={note}
						setDefaultStyle="font-family: arial; font-size: 14px;"
						setOptions={{
							buttonList,
						}}
					/>
				)}
				{customer && <div style={{ textAlign: "center" }}><Form.Text>{unsavedChanges ? "Unsaved Changes" : "Changes Saved!"}</Form.Text></div>}
			</div>
		</div>
	) : <></>;
}