import React, { useEffect,
	//useMemo,
	useState } from "react";
import Header from "./components/Header";
import Notes from "./components/Notes";
import ClientData from "./components/ClientData";
import { Col,
	//Container,
	Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Scripts from "./components/Scripts";
import Arps from "./components/CCS/Arps";
import CommonQuestions from "./components/CCS/CommonQuestions";
import useOnRefresh from "./components/hooks/useOnRefresh";
import useMessageListener from "./components/hooks/useMessageListener";
import useFive9AgentSettingModal from "./components/hooks/useFive9AgentSettingModal";
import { styled } from "styled-components";
import Sidebar from "./components/Sidebar";
import { eventEmitter } from "./utils";
import QueueWarningModal from "./components/modals/QueueWarningModal";
import LoUnavailableModal from "./components/modals/LoUnavailableModal";
import Hotlist from "./components/LO/hotlist/Hotlist";
import { FieldKeys } from "./components/hooks/useContactFields";
import { toast } from "react-toastify";
import useHotlistReminders from "./components/hooks/useHotlistReminders";
import ActionScripts from "./components/ActionScripts";
import AgentSettingsModal from "./components/modals/AgentSettingsModal";

//import {version} from './../package.json';

console.log("REACT APP RUNNING...");

const version = "1.99.34";

/**
 * Globals
 */
declare global {
	interface Window {
		BSPDASHBOARD_APP_URL: string;
	}
}

/**
 * LO View
 * @constructor
 */
function LoView() {
	const agentType = useSelector((state: any) => state.agentType);

	return agentType === "LO" ? (
		<ViewContainer>
			<div>
				<Scripts/>
			</div>
			<div>
				<ClientData/>
			</div>
			<div style={{width: 400}}>
				<Notes/>
			</div>
			<div style={{flexBasis: "100%"}}>
				<Hotlist/>
			</div>
		</ViewContainer>
	) : (
		<></>
	);
}

/**
 * CCS View
 * @constructor
 */
function CcsView() {
	const agentType = useSelector((state: any) => state.agentType);
	const customer = useSelector((state: any) => state.customer);

	return agentType === "CCS" ? (
		<ViewContainer>
			{customer && (
				<>
					{/*<div className={"card"} style={{width:"100%"}}>
						<div className={"card-body"}>
							<ActionScripts/>
						</div>
					</div>
					<br />*/}
					<div>
						<Scripts/>
					</div>
					<div style={{width: 500}}>
						<ClientData/>
					</div>
					<div style={{width: 400}}>
						<Notes/>
					</div>
				</>
			)}
			<div>
				<Arps/>
			</div>
			<div style={{ width: 500 }}>
				<CommonQuestions />
			</div>
		</ViewContainer>
	) : (
		<></>
	);
}

/**
 * LP View
 * @constructor
 */
function LpView() {
	const agentType = useSelector((state: any) => state.agentType);

	return agentType === "LP" ? (
		<Row style={{ rowGap: 20 }}>
			<Col md={6} xs={12}>
				<ClientData />
			</Col>
			<Col md={6} xs={12}>
				<Notes />
			</Col>
		</Row>
	) : (
		<></>
	);
}

/**
 * App
 * @constructor
 */
function App() {

	console.log("[INFO] EVENT - App.tsx controller init...");
	const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

	// Check if the user is authenticated when the component mounts
	useEffect(() => {
		console.log("[INFO] EVENT - AUTH | checking...");
		fetch('https://auth-test.pjmc.tech/auth/checkAuth', {
			method: 'GET',
			credentials: 'include', // Include cookies in the request
		})
		.then(response => response.json())
		.then(data => {
			if (data.authenticated) {
				console.log("[INFO] EVENT - AUTH | authenticated (data)", {data:data});
				setIsAuthenticated(true);
			} else {
				console.log("[INFO] EVENT - AUTH | unauthenticated (data)", {data:data});
				window.location.href = "https://auth-test.pjmc.tech/auth/signin?redirectUri=https://dev.pjmc.tech";
			}
		})
		.catch(() => {
			console.log("[INFO] EVENT - AUTH | unauthenticated (catch)");
			window.location.href = "https://auth-test.pjmc.tech/auth/signin?redirectUri=https://dev.pjmc.tech";
		})
		.finally(() => setLoading(false));
	}, []);



	const agentType = useSelector((state: any) => state.agentType);
	useOnRefresh();
	useMessageListener();
	//..
	useFive9AgentSettingModal();
	const theme = useSelector((state: any) => state.theme);
	const [adtBarHeight, setAdtBarHeight] = useState(0);
	const customer = useSelector((state: any) => state.customer);
	const agent = useSelector((state: any) => state.agent);
	const [customerReceived, setCustomerReceived] = useState(false);
	useHotlistReminders();
	const callStatus = useSelector((state: any) => state.callStatus);
	const callStartedAdtData = useSelector((state: any) => state.callStartedAdtData);

	useEffect(() => {
		if (Notification.permission !== "granted") {
			Notification.requestPermission();
		}
	}, []);

	useEffect(() => {
		const collapseAdt = (collapse: boolean) => {
			if (collapse) {
				const expander = document.getElementById("adt-expand");
				const adt = document.getElementById("dialer-container");

				if (expander && adt) {
					expander.innerText = ">";
					adt.style.display = "none";
				}
			}
		};

		eventEmitter.on("collapseAdt", collapseAdt);

		return () => {
			eventEmitter.off("collapseAdt", collapseAdt);
		};
	}, []);

	useEffect(() => {
		const expander = document.getElementById("adt-expand");

		if (expander) {
			if (theme === "dark") {
				expander.style.backgroundColor = "rgb(54, 56, 55, 0.5)";
			} else {
				expander.style.backgroundColor = "rgb(240, 240, 240, 0.5)";
			}
		}
	}, [theme]);

	useEffect(() => {
		const crmOwner = customer?.rawContactObj?.fields?.[FieldKeys.CRM_OWNER];

		if (
			!customerReceived &&
			customer &&
			agent &&
			agentType === "LO" &&
			crmOwner !== agent.email &&
			crmOwner != null &&
			crmOwner?.trim() !== "" &&
			crmOwner?.trim() !== "undefined"
		) {
			toast.error("This lead is owned by another LO");
			setCustomerReceived(true);
		} else if (!customer) {
			setCustomerReceived(false);
		}
	}, [agent, agentType, customer, customerReceived]);

	useEffect(() => {
		document.title =
			callStatus && callStartedAdtData
				? `${callStatus} - ${callStartedAdtData.contactDisplayName}`
				: `Hawk Hub v${version}${agentType && !agentType?.startsWith("(Unknown") ? ` - ${agentType}` : ""} `;
	}, [agentType, callStartedAdtData, callStatus]);

	useEffect(() => {
		if (agentType === "LO") {
			window.BSPDASHBOARD_APP_URL = "https://pjmc.botsplash.com";
			(function () {
				const d = document;
				const s = d.createElement("script");
				s.src = "https://pjmc.botsplash.com/y.js";
				s.async = true;
				d.getElementsByTagName("head")[0].appendChild(s);
			})();
		}
	}, [agentType]);

	useEffect(() => {
		fetch('https://auth-test.pjmc.tech/users/id', {
			method: 'GET',
			credentials: 'include', // Include cookies in the request
		})
		.then(response => response.json())
		.then(data => {
			console.log(data);
		})
		.catch((err) => {
			console.error(JSON.stringify(err));
		});
	}, []);

	if (loading) {
        return <div>Loading...</div>;
    }

	return (
		<>
			<LoUnavailableModal />
			<QueueWarningModal />
			<AgentSettingsModal />
			{/*PAGE CONTAINER*/}
			<div style={{ overflow: "auto", height: "100%", flex: 1 }}>
				<Header setAdtBarHeight={setAdtBarHeight} />
				<AppContainer>
					<main>
						<CcsView />
						<LoView />
						<LpView />
					</main>
					<Sidebar adtBarHeight={adtBarHeight} />
				</AppContainer>
			</div>
			{/*END -- PAGE CONTAINER*/}
		</>
	);
}

/**
 * StyledComponent
 */
const ViewContainer = styled.div({
	display: "flex",
	columnGap: 20,
	flexWrap: "wrap",
	rowGap: 20,
	justifyContent: "center",
	width: "100%",
});

/**
 * StyledComponent
 */
const AppContainer = styled.div`
	display: flex;
	min-height: 100%;

	main {
		flex: 1;
		align-self: baseline;
	}
`;

export default App;