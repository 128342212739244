import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { postMessage } from "../utils";

/**
 * Dispositions
 * @param buttonLabel
 * @param buttonSize
 * @param onClick
 * @constructor
 */
const Dispositions = ({
	buttonLabel = "Other Dispositions",
	buttonSize,
	onClick,
}: {
	buttonLabel?: string;
	buttonSize?: "sm" | "lg";
	onClick?: () => void;
}) => {
	const call = useSelector((state: any) => state.call);

	//Return
	return call?.Dispositions ? (
		<Dropdown className="transfer-button-full">
			<Dropdown.Toggle as={Button} className="transfer-button-full" size={buttonSize} variant="primary">
				{buttonLabel}
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{call.Dispositions.map(([key, value]: [string, string]) => (
					<Dropdown.Item
						key={key}
						onClick={() => {
							postMessage({ action: "SetDisposition", value: { id: key } });
							onClick?.();
						}}>
						{value}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	) : (
		<></>
	);
};

export default Dispositions;