import { useEffect } from "react";
import { useSelector } from "react-redux";
import { postMessage } from "../../utils";

/**
 * Use On Refresh
 */
export default function useOnRefresh() {
	const agentType = useSelector((state: any) => state.agentType);

	//Init...
    useEffect(() => {
		// in case the user refreshes, grab all data again
		postMessage({ request: "Call" });
		postMessage({ request: "AgentType" });
		postMessage({ request: "Agent" });
		postMessage({ request: "IsTransferred" });
		postMessage({ request: "Customer" });
		postMessage({ request: "CallStartedAdtData" });
		postMessage({ request: "ProductivityMinutes" });
		postMessage({ request: "Notes" });
		postMessage({ request: "CcsStats" });
		postMessage({ request: "LoStats" });
		postMessage({ request: "CallStatus" });
		postMessage({ request: "ClientWeather" });
		postMessage({ request: "ReadyState" });
	}, []);

	useEffect(() => {
		if (agentType === 'CCS') {
			postMessage({ request: "CcsStats" });
		}

		if (agentType === "LO") {
			postMessage({ request: "LoStats" });
		}
	}, [agentType])
}