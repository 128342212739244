import _ from "lodash";
import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { Accordion, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { shouldRender } from "../../utils";

/**
 * Arps
 * @constructor
 */
export default function Arps() {
	const environment = useSelector((state: any) => state.environment);
	const agent = useSelector((state: any) => state.agent);
	const call = useSelector((state: any) => state.call);
	const customer = useSelector((state: any) => state.customer);
	const [arps, setArps] = useState<any>();
	const [arpsToRender, setArpsToRender] = useState<any[]>([]);
	const [arp, setArp] = useState<Map<string, string>>();

	/**
	 * Set Arp Text -- func
	 */
	const setArpText = useCallback((title: string, arpsToRenderArr: any[]) => {
		const arpObj = arpsToRenderArr.find((s) => s.title === title);
		setArp(new Map(arpObj.arps));
	}, []);

	//Init...
	useEffect(() => {
		async function getArps() {
			//Attempt...
			try {
				const result = await axios.get(environment.arpsUrl);
				setArps(result.data);
			} catch (e) {
				toast.error("Failed to retrieve ARPs");
			}
		}

		getArps();
	}, [environment.arpsUrl]);

	useEffect(() => {
		if (arps?.length > 0 && call && customer && agent) {
			const arpsToRenderArr: any = [];

			arps.forEach((arp: any) => {
				if (shouldRender(arp.renderIf, { agent, call, customer })) {
					arpsToRenderArr.push(arp);
				}
			});

			setArpsToRender(arpsToRenderArr);
			setArpText(arpsToRenderArr[0].title, arpsToRenderArr);
		}
	}, [agent, arps, call, customer, setArpText]);

	//Return
	return arp ? (
		<div>
			<h1 style={{ textAlign: 'center' }}>ARPs/Rebuttals</h1>
			<ArpButtonsContainer>
				{arpsToRender.map((arp: any) => (
					<Button key={arp.title} onClick={() => setArpText(arp.title, arpsToRender)} value={arp.title} variant="secondary">
						{arp.title}
					</Button>
				))}
			</ArpButtonsContainer>
            <br />
			{arp && (
				<Accordion>
					{Array.from(arp.entries()).map(([question, answer], i) => {
						const key = _.uniqueId();
						return <Accordion.Item key={key} eventKey={key}>
                            <Accordion.Header>{question}</Accordion.Header>
                            <Accordion.Body>
                                <p dangerouslySetInnerHTML={{ __html: answer }} style={{ whiteSpace: 'pre-wrap' }} />
                            </Accordion.Body>
                        </Accordion.Item>;
					})}
				</Accordion>
			)}
		</div>
	) : <></>;
}

/**
 * StyledComponent
 */
const ArpButtonsContainer = styled.div({
	display: "flex",
	justifyContent: "center",
	columnGap: 10,
});