import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatPhoneNumber, formatPhoneToDigitsOnly, postMessage } from "../../utils";
import { FieldKeys } from "../hooks/useContactFields";

/**
 * CallForwardingModal
 * @param callForwardModalState
 * @constructor
 */
export default function CallForwardingModal({
	callForwardModalState,
}: {
	callForwardModalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}) {
	const [showModal, setShowModal] = callForwardModalState;
	const agent = useSelector((state: any) => state.agent);
	const [forwardNumber, setForwardNumber] = useState("");
	const [enabled, setEnabled] = useState(false);
	const [forwardNumberError, setForwardNumberError] = useState(false);

	/**
	 * Hide Modal -- func
	 */
	const hideModal = () => {
		setShowModal(false);
		setForwardNumberError(false);
	};

	/**
	 * Save action -- func
	 */
	const save = () => {
		if (formatPhoneToDigitsOnly(forwardNumber).length !== 10) {
			toast.error("Please enter a valid 10 digit phone number, unformatted");
			setForwardNumberError(true);
			return;
		} else {
			setForwardNumberError(false);
		}

		const agentContact = {
			...agent.contact,
			fields: { ...agent.contact.fields, [FieldKeys.CALL_FORWARDING]: enabled, [FieldKeys.ALT_PHONE_2]: forwardNumber },
		};

		postMessage({ action: "SaveAgentContact", value: agentContact });
		hideModal();
	};

	//Init..
	useEffect(() => {
		setForwardNumber(agent?.contact?.fields?.[FieldKeys.ALT_PHONE_2]);

		setEnabled(
			agent?.contact?.fields?.[FieldKeys.CALL_FORWARDING]?.toLowerCase() === "true" ||
				agent?.contact?.fields?.[FieldKeys.CALL_FORWARDING] === true
		);
	}, [agent?.contact?.fields]);

	const directNumber = agent?.contact?.fields?.[FieldKeys.PRIMARY_PHONE];

	//Return
	return (
		<>
			<Modal centered onHide={() => hideModal()} show={showModal}>
				<Modal.Header closeButton>
					<Modal.Title>Call Forwarding {!agent?.contact && <Spinner animation="border" />}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Direct Number</Form.Label>
							<Form.Control disabled type="text" value={formatPhoneNumber(directNumber) ?? ""} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Forward To</Form.Label>
							<Form.Control isInvalid={forwardNumberError} onChange={(e) => setForwardNumber(e.target.value)} type="text" value={forwardNumber} />
							<Form.Text muted>10 digit phone number, unformatted</Form.Text>
							{forwardNumberError && <Form.Control.Feedback type="invalid">Invalid Number Format</Form.Control.Feedback>}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Check
								checked={enabled}
								label="Enable Call Forwarding"
								onChange={(e) => setEnabled(e.target.checked)}
								type="checkbox"
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={hideModal} variant="secondary">
						Close
					</Button>
					<Button onClick={save} variant="primary">
						Submit
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
