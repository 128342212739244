import React, { useEffect, useState } from "react";
import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { styled } from "styled-components";
import { getLastContactedDate, getValidHubspotPhone, isCallableLead, postMessage, reservedLeadToBoolean } from "../utils";
import CcsStats from "./CCS/Stats";
import LoStats from "./LO/Stats";
import config from "../config.json";

export default function Productivity() {
	const [goal, setGoal] = useState<number>(0);
	const agentType = useSelector((state: any) => state.agentType);
	const productiveMins = useSelector((state: any) => state.productiveMins);
	const [hotlistCompleted, setHotlistCompleted] = useState(0);
	const [gradientColor, setGradientColor] = useState("rgb(255, 0, 0)");
	const hotlist = useSelector((state: any) => state.hotlist);

	//Init...
	useEffect(() => {
		if (hotlist?.length) {
			const numerator = (hotlist as any[]).filter((item: any) => {
				const lastContacted = getLastContactedDate(item);
				return (
					getValidHubspotPhone(item) &&
					!reservedLeadToBoolean(item.properties?.reserved_lead) &&
					lastContacted &&
					new Date(lastContacted).toDateString() === new Date().toDateString() &&
					(!item.properties?.callback_appointment_ts || new Date(item.properties.callback_appointment_ts) < new Date())
				);
			});

			const denominator = (hotlist as any[]).filter((item: any) => {
				return (
					getValidHubspotPhone(item) &&
					!reservedLeadToBoolean(item.properties?.reserved_lead) &&
					(!item.properties?.callback_appointment_ts || new Date(item.properties.callback_appointment_ts) < new Date())
				);
			});

			setHotlistCompleted(Math.round((numerator.length / denominator.length) * 100));
		}
	}, [hotlist]);

	useEffect(() => {
		if (agentType === "CCS") {
			setGoal(420);
		}

		if (agentType === "LO") {
			setGoal(240);
		}
	}, [agentType]);

	useEffect(() => {
		let intervalId = setInterval(() => {
			postMessage({ request: "ProductivityMinutes" });
		}, config.metricRefresh.Productivity);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const minutes = productiveMins?.ProductiveMins ?? 0;
	const lastUpdated = productiveMins?.Last_Updated ? new Date(productiveMins.Last_Updated) : undefined;

	useEffect(() => {
		function interpolateColor(color1: any, color2: any, factor: any) {
			const result = color1.slice();
			for (let i = 0; i < 3; i++) {
				result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
			}
			return result;
		}

		function rgbToCss(rgb: any) {
			return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
		}

		function getGradientColor() {
			const green = [0, 255, 0];
			const red = [255, 0, 0];
			const color = interpolateColor(red, green, hotlistCompleted / 100);
			const colorCss = rgbToCss(color);
			setGradientColor(colorCss);
		}

		getGradientColor();
	}, [hotlistCompleted]);

	//Return
	return (
		<ProductivityContainer>
			<CcsStats />
			<LoStats />
			<div style={{ display: "flex", alignItems: "baseline", columnGap: agentType === "LO" ? 20 : 0 }}>
				<div style={{ flex: 1 }}>
					<OverlayTrigger
						overlay={
							<Tooltip className="in" placement="bottom">
								{lastUpdated ? `Last refresh: ${lastUpdated.toLocaleString()}` : "Data refreshes every 15 minutes"}
							</Tooltip>
						}
						placement="bottom">
						<ProgressBar
							animated={minutes < goal}
							label={`${minutes}/${goal} Mins`}
							now={(minutes / goal) * 100}
							style={{ marginBottom: 20, marginTop: 10 }}
							variant={minutes >= goal ? "success" : undefined}
						/>
					</OverlayTrigger>
				</div>
				{agentType === "LO" && (
					<div style={{ width: 60, height: 60 }}>
						<OverlayTrigger
							overlay={
								<Tooltip className="in" placement="bottom">
									Follow Up Leads Called
								</Tooltip>
							}
							placement="bottom">
							<div>
								<CircularProgressbar
									styles={buildStyles({
										textSize: "35px",
										pathColor: gradientColor,
										textColor: gradientColor,
									})}
									text={hotlistCompleted === 0 ? "X" : hotlistCompleted >= 100 ? "✓" : `${hotlistCompleted}%`}
									value={hotlistCompleted}
								/>
							</div>
						</OverlayTrigger>
					</div>
				)}
			</div>
		</ProductivityContainer>
	);
}

/**
 * StyledComponent
 */
const ProductivityContainer = styled.div`
	padding: 10px 20px;
`;