import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { eventEmitter, postMessage } from "../../utils";

/**
 * LoUnavailableModal
 * @constructor
 */
const LoUnavailableModal = () => {
	const [showModal, setShowModal] = useState(false);

	/**
	 * Handle Close -- func
	 */
	const handleClose = () => {
		setShowModal(false);
	};

	/**
	 * Show Modal Handler -- func
	 * @param show
	 */
	const showModalHandler = (show: boolean) => {
		setShowModal(show);
	};

	//Init
	useEffect(() => {
		eventEmitter.on("showLoUnavailable", showModalHandler);

		return () => {
			eventEmitter.off("showLoUnavailable", showModalHandler);
		};
	}, []);

	//Return
	return (
		<>
			<Modal centered onHide={handleClose} show={showModal}>
				<Modal.Header closeButton>
					<Modal.Title>Loan Officer Unavailable</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>A Loan Officer was not available to pick up the call within 60 seconds. You were credited for the transfer.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={() => {
							postMessage({ action: "SetDisposition", value: "disLoUnavailable" });
							handleClose();
						}}
						variant="secondary">
						End Call as LO Unavailable
					</Button>
					<Button
						onClick={() => {
							postMessage({ action: "TransferAgain" });
							handleClose();
						}}
						variant="success">
						Try Again
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default LoUnavailableModal;
